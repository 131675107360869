
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.accordion-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-width: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 2rem;
  padding-right: 0px;
}

.accordion-button.mobile {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 1rem !important;
  padding-right: 0px !important;
  font-size: var(--fs-base) !important;
  line-height: var(--lh-base) !important;
  line-height: 1.25rem !important;
}

.accordion-button.active {
  background-color: var(--outline-soft-black);
  color: var(--outline-white);
}

.accordion-button.inactive {
  background-color: var(--outline-white);
  color: var(--outline-soft-black);
}

.accordion-button.clean {
  border-bottom-width: 1px;
  border-style: solid;
  border-color: var(--outline-gray-300);
  padding-left: 0px;
}

.accordion-button.clean.active {
  background-color: var(--outline-white) !important;
  color: var(--outline-soft-black) !important;
}

.accordion-button.clean.inactive {
  color: var(--outline-soft-black);
}

.accordion-content {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.accordion-content.mobile {
  padding-left: 1rem;
  padding-right: 1rem;
}

.clean .accordion-content {
  padding-left: 0px;
  padding-right: 0px;
}

.accordion-label {
  text-align: left;
  max-width:75%;
}

::slotted(outline-heading){
  margin:1rem 0;
}

.accordion-heading {
  margin: 0px;
  display: flex;
}

.accordion-icon {
  display: flex;
  height: 100%;
  width: 5rem;
  align-items: center;
  justify-content: center;
}

.accordion-icon.clean {
  background-color: var(--outline-white) !important;
  color: var(--outline-soft-black) !important;
}

.accordion-icon.active {
  background-color: var(--outline-soft-black);
  color: var(--outline-white);
}

.accordion-icon.inactive {
  background-color: var(--outline-gray-600);
  color: var(--outline-white);
}

.accordion-panel {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.375rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--outline-soft-black);
}

.accordion-panel.clean {
  border-width: 0px;
}
`;