
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.links{
  padding-top:var(--outline-admin-links--default-y-spacing)
  ;
  padding-top:var(
    --outline-admin-links-y-spacing,
    var(--outline-admin-links--default-y-spacing)
  );
  padding-bottom:var(--outline-admin-links--default-y-spacing)
  ;
  padding-bottom:var(
    --outline-admin-links-y-spacing,
    var(--outline-admin-links--default-y-spacing)
  );
}

ul{
  display:flex;
  list-style-type:none;
  margin:0;
  padding:0;
}

li{
  margin:0;
  padding:0;
  display:block;
}

a{
  position:relative;
  text-decoration:none;
  padding-top:var(--outline-admin-links--default-link-y-spacing)
  ;
  padding-top:var(
    --outline-admin-links-link-y-spacing,
    var(--outline-admin-links--default-link-y-spacing)
  );
  padding-bottom:var(--outline-admin-links--default-link-y-spacing)
  ;
  padding-bottom:var(
    --outline-admin-links-link-y-spacing,
    var(--outline-admin-links--default-link-y-spacing)
  );
  padding-left:var(--outline-admin-links--default-link-x-spacing)
  ;
  padding-left:var(
    --outline-admin-links-link-x-spacing,
    var(--outline-admin-links--default-link-x-spacing)
  );
  padding-right:var(--outline-admin-links--default-link-x-spacing)
  ;
  padding-right:var(
    --outline-admin-links-link-x-spacing,
    var(--outline-admin-links--default-link-x-spacing)
  );
  background:var(--outline-admin-links--default-bg-default)
  ;
  background:var(
    --outline-admin-links-bg-default,
    var(--outline-admin-links--default-bg-default)
  );
  color:var(--outline-admin-links--default-color-default)
  ;
  color:var(
    --outline-admin-links-color-default,
    var(--outline-admin-links--default-color-default)
  );
}

a:hover{
    outline:none;
    background:var(--outline-admin-links--default-bg-hover)
    ;
    background:var(
      --outline-admin-links-bg-hover,
      var(--outline-admin-links--default-bg-hover)
    );
    color:var(--outline-admin-links--default-color-hover)
    ;
    color:var(
      --outline-admin-links-color-hover,
      var(--outline-admin-links--default-color-hover)
    );
  }

a:focus{
    outline:none;
    background:var(--outline-admin-links--default-bg-focus)
    ;
    background:var(
      --outline-admin-links-bg-focus,
      var(--outline-admin-links--default-bg-focus)
    );
    color:var(--outline-admin-links--default-color-focus)
    ;
    color:var(
      --outline-admin-links-color-focus,
      var(--outline-admin-links--default-color-focus)
    );
  }

a:not(.is-active):hover::before,
  a:not(.is-active):focus::before{
    position:absolute;
    top:50%;
    left:50%;
    width:calc(100%);
    height:100%;
    content:'';
    transform:translate(-50%, -50%);
    border:solid 2px
      var(--outline-admin-links--default-color-hover)
      ;
    border:solid 2px
      var(
        --outline-admin-links-color-hover,
        var(--outline-admin-links--default-color-hover)
      );
    border-radius:0.25rem;
  }

a.is-active{
    outline:solid 2px
      var(--outline-admin-links--default-color-hover)
      ;
    outline:solid 2px
      var(
        --outline-admin-links-color-hover,
        var(--outline-admin-links--default-color-hover)
      );
    background:var(--outline-admin-links--default-bg-active)
    ;
    background:var(
      --outline-admin-links-bg-active,
      var(--outline-admin-links--default-bg-active)
    );
    color:var(--outline-admin-links--default-color-active)
    ;
    color:var(
      --outline-admin-links-color-active,
      var(--outline-admin-links--default-color-active)
    );
    z-index:1;
  }

a.is-active:hover,
    a.is-active:focus{
      outline-offset:2px;
    }
`;