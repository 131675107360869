
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  display: block;
}

.card {
  position: relative;
  box-sizing: border-box;
  display: flex;
  border-radius: 0.5rem;
  background-color: var(--neutral-white);
  color: var(--gray-800);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.info {
  width: 66.666667%;
  flex: 1 1 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-family: var(--ff-display);
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

.details {
  display: flex;
}

.body {
  width: 83.333333%;
}

.tags {
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.state {
  margin-bottom: 0.5rem;
  display: inline-block;
  border-radius: 0.125rem;
  background-color: var(--green-400);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  line-height: 1.25rem;
  color: var(--neutral-white);
  font-size:10px;
}

.type {
  margin-bottom: 0.5rem;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-light);
  color: var(--blue-900);
}

.code {
  margin-bottom: 0.5rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  font-weight: var(--fw-bold);
  color: var(--neutral-grayDark);
}

.place {
  margin-bottom: 0.25rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
}

.area {
  display: none;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

.divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top:1px solid #ddd;
}

.vertical-divider {
  display: none;
}

.image {
  width: 33.333333%;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.image img {
  height: 100%;
}

.image img {
  width: 100%;
}

.image img {
  object-fit: cover;
}

.svg {
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
  color: var(--gray-600);
}

.canon {
  display: flex;
}

.canon__inner {
  display: flex;
}

.canon--scratched {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.canon--fair {
  margin-left: 0.5rem;
}

.canonSvg {
  margin-right: 0.25rem;
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
  color: var(--gray-600);
}

.brand {
  display: none;
}

.custom-note {
  margin-top: 0.25rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  color: var(--gray-500);
}

@media (min-width: 768px){
  .details {
    gap: 2.75rem;
  }

  .info {
    font-size: var(--fs-lg);
    line-height: var(--lh-lg);
  }

  .type {
    margin-bottom: 1rem;
  }

  .type {
    font-size: var(--fs-2xl);
    line-height: var(--lh-2xl);
  }

  .type {
    font-weight: var(--fw-normal);
  }

  .type {
    text-transform: capitalize;
  }

  .code {
    margin-bottom: 0.75rem;
  }

  .area {
    margin-bottom: 0.5rem;
  }

  .area {
    display: block;
  }

  .place {
    margin-bottom: 0.5rem;
  }

  .place {
    font-size: var(--fs-sm);
    line-height: var(--lh-sm);
  }

  .vertical-divider {
    display: block;
  }

  .vertical-divider{
    border-left:1px solid #ddd;
  }

  .image{
    width:260px;
  }

  .svg {
    margin-right: 0.25rem;
  }

  .svg {
    height: 1.25rem;
  }

  .svg {
    width: 1.25rem;
  }

  .canon {
    padding-top: 0.625rem;
  }

  .canon {
    font-size: var(--fs-xl);
    line-height: var(--lh-xl);
  }

  .canonSvg {
    margin-right: 0.5rem;
  }

  .canonSvg {
    height: 1.5rem;
  }

  .canonSvg {
    width: 1.5rem;
  }

  .canonSvg {
    padding-top: 0.25rem;
  }

  .brand {
    margin-left: 0px;
    margin-right: 0px;
  }

  .brand {
    margin-top: auto;
    margin-bottom: auto;
  }

  .brand {
    display: block;
  }

    .brand img{
      width:100px;
    }
}

.flags {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
}

.actions {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
}
`;