import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-zoho-icon.css.lit';
import icon from './assets/zoho.png';
// import axios from 'axios';

/**
 * The Coninsa Card component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-zoho-icon')
export class CoZohoIcon extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Property id.
   */
  @property()
  propertyId: string;

  /**
   * Image.
   */
  @state()
  disabled = false;

  /**
   * Url.
   */
  @property()
  url: string;

  getUrlParams() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, prospect, origin, email, phone] = window.location.pathname
      .substring(1)
      .split('/');

    return { prospect, origin, email, phone };
  }

  handleClick() {
    this.disabled = true;
    const params = this.getUrlParams();

    if (!params.prospect) {
      alert('No hay ningún id de prospecto asociado');
    }

    const options = {
      id_prospecto: params.prospect,
      id_inmueble: this.propertyId,
      origin: params.origin,
    };

    fetch(this.url, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => true)
      // eslint-disable-next-line no-console
      .catch(error => console.error('Error:', error))
      .then(() => alert('El inmueble se ha asociado correctamente'));
  }

  render(): TemplateResult {
    return html`
      <button
        class="zoho"
        @click=${this.handleClick}
        ?disabled=${this.disabled}
      >
        <img class="icon" src=${icon} />
      </button>
    `;
  }
}
