import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-ad-card.css.lit';

/**
 * The Coninsa Ad Card component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-ad-card')
export class CoAdCard extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  render(): TemplateResult {
    return html`
      <div class="ad_card">
        <slot name="content"></slot>
      </div>
    `;
  }
}
