import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-tab-item.css.lit';

/**
 * The Coninsa Tab Item component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-tab-item')
export class CoTabItem extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Label.
   */
  @property()
  label: string;

  /**
   * Value.
   */
  @property()
  value: string;

  render(): TemplateResult {
    return html`
      <div class="tab-item">
        <div class="label">${this.label}</div>
        <div class="value">${this.value}</div>
      </div>
    `;
  }
}
