import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-active-filter.css.lit';

/**
 * The Coninsa Active Filter component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-active-filter')
export class CoActiveFilters extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Result name
   */
  @property({ type: String })
  name: string;

  /**
   * Result url
   */
  @property({ type: String })
  url: string;

  render(): TemplateResult {
    return html`
      <a class="tag" href=${this.url}>
        ${this.name}
        <svg
          class="close-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="5"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </a>
    `;
  }
}
