
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

    display: block
}

.tag {

    display: inline-flex;

    cursor: pointer;

    border-radius: 1rem;

    border-width: 1px;

    border-style: solid;

    border-color: var(--coninsa-blue-300);

    padding-left: 1.5rem;

    padding-right: 1.5rem;

    padding-top: 0.25rem;

    padding-bottom: 0.25rem;

    color: var(--coninsa-blue-300)
}

.tag:hover {

    background-color: var(--coninsa-blue-300);

    color: var(--coninsa-white-300)
}
`;