
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host{
  display:inline-flex;
  vertical-align:middle;
}

.btn{
  padding:0 !important;
}

::slotted(.button__trigger){
  display:flex;
  padding:var(--outline-button--default-padding);
  padding:var(--outline-btn-padding, var(--outline-button--default-padding));
}

[trigger-variant='primary'] ::slotted(.button__trigger){
  padding:var(--outline-button--default--primary-padding);
  padding:var(
    --outline--button--primary-padding,
    var(--outline-btn-padding, var(--outline-button--default--primary-padding))
  );
}

[trigger-variant='primary']:hover ::slotted(.icon__trigger) .btn.primary{
  background-color:var(--outline-button--default--primary-bg-color-hover)
  ;
  background-color:var(
    --outline--button--primary-bg-color-hover,
    var(--outline-button--default--primary-bg-color-hover)
  );
  color:var(--outline-button--default--primary-text-color-hover)
  ;
  color:var(
    --outline--button--primary-text-color-hover,
    var(--outline-button--default--primary-text-color-hover)
  );
  font-weight:var(--outline-button--default--primary-weight-hover)
  ;
  font-weight:var(
    --outline--button--primary-weight-hover,
    var(--outline-button--default--primary-weight-hover)
  );
  box-shadow:var(--outline-button--default--primary-ring-hover)
  ;
  box-shadow:var(
    --outline--button--primary-ring-hover,
    var(--outline-button--default--primary-ring-hover)
  );
}

[trigger-variant='secondary'] ::slotted(.button__trigger){
  padding:var(--outline-button--default--secondary-padding)
    ;
  padding:var(
    --outline--button--secondary-padding,
    var(
      --outline-btn-padding,
      var(--outline-button--default--secondary-padding)
    )
  );
}

[trigger-variant='tertiary'] ::slotted(.button__trigger){
  padding:var(--outline-button--default--tertiary-padding);
  padding:var(
    --outline--button--tertiary-padding,
    var(--outline-btn-padding, var(--outline-button--default--tertiary-padding))
  );
}

[trigger-variant='link'] ::slotted(.button__trigger){
  padding:var(--outline-button--default--link-padding);
  padding:var(
    --outline--button--link-padding,
    var(--outline-btn-padding, var(--outline-button--default--link-padding))
  );
}

::slotted(.icon__trigger){
  display:flex;
  height:100%;
}

::slotted(.icon__bordered){
  border-left:1px solid currentColor;
}

:host([button-variant='link']) ::slotted(.icon__bordered){
  border-left-width:0px !important;
}

:host([button-variant='link']) ::slotted(.button__trigger){
  //padding-right:var(--spacing-2);
}
`;