
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.outline-breadcrumbs{
  color:var(--outline-breadcrumb--default-text-color-default)
  ;
  color:var(
    --outline-breadcrumb-text-color-default,
    var(--outline-breadcrumb--default-text-color-default)
  );
  background:var(--outline-breadcrumb--default-bg-color)
  ;
  background:var(
    --outline-breadcrumb-bg-color,
    var(--outline-breadcrumb--default-bg-color)
  );
  padding:var(--outline-breadcrumb--default-bg-padding)
  ;
  padding:var(
    --outline-breadcrumb-bg-padding,
    var(--outline-breadcrumb--default-bg-padding)
  );
}

a, ::slotted(a) {
  font-family: var(--ff-body);
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  -webkit-text-decoration:var(--outline-breadcrumb--default-link-decoration-default)
  ;
          text-decoration:var(--outline-breadcrumb--default-link-decoration-default)
  ;
  -webkit-text-decoration:var(
    --outline-breadcrumb-link-decoration-default,
    var(--outline-breadcrumb--default-link-decoration-default)
  );
          text-decoration:var(
    --outline-breadcrumb-link-decoration-default,
    var(--outline-breadcrumb--default-link-decoration-default)
  );
  color:var(--outline-breadcrumb--default-link-color-default)
  ;
  color:var(
    --outline-breadcrumb-link-color-default,
    var(--outline-breadcrumb--default-link-color-default)
  );
}

a:hover,
::slotted(a:hover){
  -webkit-text-decoration:var(--outline-breadcrumb--default-link-decoration-hover)
  ;
          text-decoration:var(--outline-breadcrumb--default-link-decoration-hover)
  ;
  -webkit-text-decoration:var(
    --outline-breadcrumb-link-decoration-hover,
    var(--outline-breadcrumb--default-link-decoration-hover)
  );
          text-decoration:var(
    --outline-breadcrumb-link-decoration-hover,
    var(--outline-breadcrumb--default-link-decoration-hover)
  );
  color:var(--outline-breadcrumb--default-link-color-hover)
  ;
  color:var(
    --outline-breadcrumb-link-color-hover,
    var(--outline-breadcrumb--default-link-color-hover)
  );
}

a:focus,
::slotted(a:focus){
  -webkit-text-decoration:var(--outline-breadcrumb--default-link-decoration-focus)
  ;
          text-decoration:var(--outline-breadcrumb--default-link-decoration-focus)
  ;
  -webkit-text-decoration:var(
    --outline-breadcrumb-link-decoration-focus,
    var(--outline-breadcrumb--default-link-decoration-focus)
  );
          text-decoration:var(
    --outline-breadcrumb-link-decoration-focus,
    var(--outline-breadcrumb--default-link-decoration-focus)
  );
  outline:none;
  color:var(--outline-breadcrumb--default-link-color-focus)
  ;
  color:var(
    --outline-breadcrumb-link-color-focus,
    var(--outline-breadcrumb--default-link-color-focus)
  );
}

.separator{
  margin-left:var(--outline-breadcrumb--default-separator-spacing)
  ;
  margin-left:var(
    --outline-breadcrumb-separator-spacing,
    var(--outline-breadcrumb--default-separator-spacing)
  );
  margin-right:var(--outline-breadcrumb--default-separator-spacing)
  ;
  margin-right:var(
    --outline-breadcrumb-separator-spacing,
    var(--outline-breadcrumb--default-separator-spacing)
  );
}
`;