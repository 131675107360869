
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.featured-tab {

  display: flex;

  min-width: 134px;

  cursor: pointer;

  flex-direction: column;

  align-items: center;

  border-radius: 0.5rem;

  border-style: none;

  background-color: var(--neutral-white);

  padding-left: 0.75rem;

  padding-right: 0.75rem;

  padding-top: 0.5rem;

  padding-bottom: 0.5rem;

  color: var(--coninsa-blue-900);

  box-shadow:0px 1px 4px #00000029
}

.featured-tab--activated {

  background-color: var(--coninsa-blue-300);

  color: var(--coninsa-white-300)
}

.featured-tab:hover {

  background-color: var(--coninsa-blue-300);

  color: var(--coninsa-white-300)
}

::slotted(outline-icon) {

  margin-bottom: 0.5rem;

  color: var(--coninsa-blue-300)
}

.featured-tab:hover ::slotted(outline-icon) {

  color: var(--coninsa-white-300)
}

.featured-tab--activated ::slotted(outline-icon) {

  color: var(--coninsa-white-300)
}
`;