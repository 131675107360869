import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-search-msg.css.lit';

/**
 * The Coninsa Search Message component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-search-msg')
export class CoSearchMsg extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Search name
   */
  @property({ type: String })
  name: string;

  getDefaultInputTextBasedOnSearch() {
    const search = document.location.search;
    const params = new URLSearchParams(search);
    const inputValue = params.get('search') || 'adasdas';
    return inputValue;
  }

  @state()
  selectedId: string;

  render(): TemplateResult {
    return html`
      <div class="msg">
        No encontramos resultados exactos con el criterio
        <strong class="highlighted"
          >${this.getDefaultInputTextBasedOnSearch()}</strong
        >, sin embargo, te ofrecemos estas opciones o te invitamos a buscar
        nuevamente usando otras palabras
      </div>
    `;
  }
}
