
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

    display: block
}

.item-diagram {

    margin-bottom: 1.75rem;

    display: flex
}

@media (min-width: 768px) {

    .item-diagram {

        margin-bottom: auto
    }

    .item-diagram {

        display: block
    }

    .item-diagram {

        text-align: center
    }
}

.image {

    margin-right: 1rem;

    height: 7rem;

    width: 7rem;

    border-radius: 9999px;

    object-fit: cover
}

@media (min-width: 768px) {

    .image {

        margin-right: auto
    }
}

.content {

    margin-left: 0px;

    margin-right: 0px;

    margin-top: auto;

    margin-bottom: auto
}

.heading {

    margin-bottom: 0.25rem;

    font-size: var(--fs-sm);

    line-height: var(--lh-sm);

    text-transform: uppercase;

    color: var(--coninsa-blue-900)
}

::slotted(p) {

    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

    font-size: var(--fs-base);

    line-height: var(--lh-base)
}
`;