import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-outstanding.css.lit';
type OutstandingVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quinary'
  | 'senary';

/**
 * The Coninsa Search Message component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-outstanding')
export class CoOutstanding extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Search color
   */
  @property({ type: String })
  variant: OutstandingVariant = 'primary';

  render(): TemplateResult {
    const classes = classMap({
      outstanding: true,
      [this.variant]: true,
    });
    return html`
      <div class=${classes}>
        <slot></slot>
      </div>
    `;
  }
}
