
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.list{
  border:unset;
  margin:0;
  padding:0;
  display:flex;
  flex-wrap:wrap;
}

ul.list,
ul.grid{
  list-style-type:none;
}

ul.list > ::slotted(li),
ol.list > ::slotted(li),
ul.grid > ::slotted(li),
ol.grid > ::slotted(li){
  display:flex;
  align-items:center;
  white-space:nowrap;
}

.list.row ::slotted(*:not(:last-of-type)){
  margin-right:var(--outline--list-default-spacing)
  ;
  margin-right:var(
    --outline--list-spacing,
    var(--outline--list-default-spacing)
  );
}

.list.column ::slotted(*:not(:last-of-type)),
.list.col-center ::slotted(*:not(:last-of-type)){
  margin-bottom:var(--outline--list-default-spacing)
  ;
  margin-bottom:var(
    --outline--list-spacing,
    var(--outline--list-default-spacing)
  );
}

.columns--2,
.columns--3,
.columns--4,
.columns--5,
.columns--6{
  display:flex;
  flex-direction:column;
}

.row{
  display:flex;
  flex-direction:row;
}

.column{
  display:flex;
  flex-direction:column;
}

.col-center{
  display:flex;
  flex-direction:column;
  align-items:center;
}
`;