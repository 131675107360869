
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  display: block
}

.card {
  display: none;
  background-color: var(--neutral-white);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2rem;
  color: var(--gray-800);
  box-shadow:0px 1px 4px #00000029
}

.card--visible {
  display: block
}

.actions {
  width: 100%;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 100%) {

  .actions {
    max-width: 100%
  }
}

@media (min-width: 1152px) {

  .actions {
    max-width: 1152px
  }
}

.actions {
  position: relative;
  padding-top: 3.5rem
}

.content {
  width: 100%;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 100%) {

  .content {
    max-width: 100%
  }
}

@media (min-width: 1152px) {

  .content {
    max-width: 1152px
  }
}

.close_btn {
  position: absolute;
  top: 0.5rem;
  right: 0px;
  cursor: pointer;
  border-width: 0px;
  background-color: var(--neutral-transparent);
  text-align: right
}
`;