
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host{
  display:inline-flex;
}

.dropdown{
  display:flex;
  position:relative;
}

.dropdown__trigger{
}

.dropdown--open .btn.primary{
    background-color:var(--outline-button--default--primary-bg-color-hover)
    ;
    background-color:var(
      --outline--button--primary-bg-color-hover,
      var(--outline-button--default--primary-bg-color-hover)
    );
    color:var(--outline-button--default--primary-text-color-hover)
    ;
    color:var(
      --outline--button--primary-text-color-hover,
      var(--outline-button--default--primary-text-color-hover)
    );
    font-weight:var(--outline-button--default--primary-weight-hover)
    ;
    font-weight:var(
      --outline--button--primary-weight-hover,
      var(--outline-button--default--primary-weight-hover)
    );
    box-shadow:var(--outline-button--default--primary-ring-hover)
    ;
    box-shadow:var(
      --outline--button--primary-ring-hover,
      var(--outline-button--default--primary-ring-hover)
    );
  }

.dropdown--open .dropdown__panel{
  margin:0;
  position:absolute;
  z-index:50;
  min-width:350px;
  border-width:2px;
  border-style:solid;
  border-color:var(--outline-gray-400);
  background-color:var(--outline-gray-200);
  color:var(--outline-black);
  padding:1rem;
  pointer-events:all;
}

outline-split-button{
}

outline-button .btn{
    padding:var(--outline-button--default-padding);
    padding:var(--outline-btn-padding, var(--outline-button--default-padding));
  }

outline-button .btn.primary{
    padding:var(--outline-button--default--primary-padding)
      ;
    padding:var(
      --outline--button--primary-padding,
      var(
        --outline-btn-padding,
        var(--outline-button--default--primary-padding)
      )
    );
  }

outline-button .btn.secondary{
    padding:var(--outline-button--default--secondary-padding)
      ;
    padding:var(
      --outline--button--secondary-padding,
      var(
        --outline-btn-padding,
        var(--outline-button--default--secondary-padding)
      )
    );
  }

outline-button .btn.tertiary{
    padding:var(--outline-button--default--tertiary-padding)
      ;
    padding:var(
      --outline--button--tertiary-padding,
      var(
        --outline-btn-padding,
        var(--outline-button--default--tertiary-padding)
      )
    );
  }

outline-button .btn.link{
    padding:var(--outline-button--default--link-padding);
    padding:var(
      --outline--button--link-padding,
      var(--outline-btn-padding, var(--outline-button--default--link-padding))
    );
  }

outline-button .btn.primary:hover{
  }

outline-button .icon__trigger{
    display:flex;
    margin-left:1rem;
  }
`;