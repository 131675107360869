import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-card.css.lit';

/**
 * The Coninsa Card component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-card')
export class CoCard extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Url.
   */
  @property()
  url: string;

  render(): TemplateResult {
    return html`
      <a class="card" href=${this.url} target="_blank">
        <div class="image">
          <div class="flags-section">
            <slot name="flags"></slot>
          </div>

          <slot name="image"></slot>
        </div>
        <div class="content">
          <slot name="content"></slot>
        </div>
      </a>
    `;
  }
}
