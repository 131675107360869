import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-result.css.lit';

/**
 * The Coninsa Result component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-result')
export class CoResult extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Result name
   */
  @property({ type: String })
  name: string;

  render(): TemplateResult {
    return html`
      <div class="result">
        ${this.name}
        <hr />
      </div>
    `;
  }
}
