
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
details {
  margin-bottom: 1rem;
}

details summary:after {
  position: absolute;
  right: 0.25rem;
  top: 0.5rem;
  display: block;
  height: 1rem;
  width: 1rem;
  background-image:url("data:image/svg+xml,%3Csvg stroke='%23fbc800' fill='%23fbc800' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z'%3E%3C/path%3E%3C/svg%3E%0A");
  content:'';
}

@media (min-width: 768px){
  details summary:after{
    background-image:url("data:image/svg+xml,%3Csvg stroke='%23fbc800' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='16'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  }

  details[open] summary:after{
    background-image:url("data:image/svg+xml,%3Csvg stroke='%23fbc800' fill='none' stroke-width='2' viewBox='0 0 24 24' stroke-linecap='round' stroke-linejoin='round' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  }
}

summary {
  position: relative;
  cursor: pointer;
  list-style-type: none;
  padding: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  font-weight: var(--fw-bold);
  text-transform: uppercase;
}

summary:focus{
    outline:none;
  }

summary::-webkit-details-marker{
    display:none;
  }
`;