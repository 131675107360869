
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

  display: inline-block;
}

.dropdown .content {

  display: none;
}

.dropdown--active .content {

  display: block;
}

.trigger {

  display: flex;

  height: 3rem;

  align-items: center;

  padding: 0.625rem;

  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  font-size: var(--fs-sm);

  line-height: var(--lh-sm);
}

.trigger outline-icon {

  margin-left: 0.75rem;
}

.dropdown--default .trigger {

  border-radius: 0.25rem;

  border-width: 1px;

  border-style: solid;

  border-color: var(--gray-300);

  background-color: var(--neutral-white);
}

.dropdown--alternative .trigger {

  border-style: none;

  background-color: var(--neutral-transparent);

  color: var(--coninsa-blue-300);
}

.content {

  position: absolute;

  z-index: 10;

  margin-top: 0.5rem;

  border-radius: 0.25rem;

  border-width: 1px;

  border-style: solid;

  border-color: var(--gray-100);

  background-color: var(--neutral-white);

  padding-left: 1rem;

  padding-right: 1rem;

  padding-top: 0.5rem;

  padding-bottom: 0.5rem;

  font-size: var(--fs-sm);

  line-height: var(--lh-sm);

  opacity: 1;

  box-shadow:0px 3px 6px #00000029;
}

.content::before{
    content:'';
  }

.content::before {

  position: absolute;
}

.content::before {

  left: 2rem;
}

.content::before {

  display: block;
}

.content::before {

  height: 0.5rem;
}

.content::before {

  width: 0.5rem;
}

.content::before {

  border-top-width: 1px;
}

.content::before {

  border-left-width: 1px;
}

.content::before {

  border-style: solid;
}

.content::before {

  border-color: var(--neutral-transparent);
}

.content::before {

  background-color: var(--neutral-white);
}

.content::before{
    box-shadow:-2px -2px 4px #00000015;
    top:-4px;
    transform:rotate(45deg);
  }

.content ::slotted(div) {

  padding-top: 0.25rem;

  padding-bottom: 0.25rem;
}
`;