
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

    display: block
}

.featured-item {

    margin-bottom: 1rem
}

.content {

    margin-bottom: 0.75rem;

    display: flex
}

.icon {

    margin-right: 0.5rem;

    height: 2rem;

    width: 2rem;

    object-fit: cover
}

@media (min-width: 768px) {

    .icon {

        height: 2.25rem
    }

    .icon {

        width: 2.25rem
    }
}

.label {

    margin-left: 0px;

    margin-right: 0px;

    margin-top: auto;

    margin-bottom: auto;

    font-size: var(--fs-xs);

    line-height: var(--lh-xs);

    color: var(--outline-gray-500);

    opacity: 1
}

@media (min-width: 768px) {

    .label {

        font-size: var(--fs-sm);

        line-height: var(--lh-sm)
    }
}

.divider {

    border-width: 1px;

    border-style: solid;

    border-color: var(--coninsa-gray-300)
}
`;