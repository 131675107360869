
import { css } from 'lit';
export default css`
/* Apply CSS Variables to the host element. */
:host {
--outline-button--default--link-padding:var(--outline-button--default-padding);

--outline-button--default--link-radius:var(--outline-button--default-radius);

--outline-button--default--link-bg-color-default:var(--outline-transparent);

--outline-button--default--link-text-color-default:var(--outline--link-default-color-default);

--outline-button--default--link-decoration-default:none;

--outline-button--default--link-weight-default:var(--outline-button--default-weight);

--outline-button--default--link-ring-default:none;

--outline-button--default--link-bg-color-hover:var(--outline-transparent);

--outline-button--default--link-text-color-hover:var(--outline--link-default-color-hover);

--outline-button--default--link-decoration-hover:underline;

--outline-button--default--link-weight-hover:var(--outline-button--default-weight);

--outline-button--default--link-ring-hover:none;

--outline-button--default--link-bg-color-focus:var(--outline-transparent);

--outline-button--default--link-text-color-focus:var(--outline--link-default-color-focus);

--outline-button--default--link-decoration-focus:none;

--outline-button--default--link-weight-focus:var(--outline-button--default-weight);

--outline-button--default--link-ring-focus:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-gray-500);

--outline-button--default--link--disabled-bg-color:var(--outline-transparent);

--outline-button--default--link--disabled-text-color:var(--outline-gray-500);

--outline-button--default--link--disabled-decoration:none;

--outline-button--default--link--disabled-weight:var(--outline-button--default-weight);

--outline-button--default--link--disabled-ring:none;

--outline-button--default--link--disabled-bg-color-focus:var(--outline-transparent);

--outline-button--default--link--disabled-text-color-focus:var(--outline-gray-500);

--outline-button--default--link--disabled-decoration-focus:none;

--outline-button--default--link--disabled-weight-focus:var(--outline-button--default-weight);

--outline-button--default--link--disabled-ring-focus:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-gray-500);
`;