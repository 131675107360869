import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-search.css.lit';

import {
  BULDING_LEASING_PATH,
  BULDING_SELLING_PATH,
  BULDING_PROJECT_PATH,
} from '../config';

/**
 * The Coninsa Search component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-search')
export class CoSearch extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Search name
   */
  @property({ type: String })
  name: string;

  @state()
  selectedId: string;

  options = [
    {
      id: 'property-leasing',
      label: 'Arrendar',
      placeholder: 'Escribe aquí ciudad, zona o barrio',
      url: BULDING_LEASING_PATH,
    },
    {
      id: 'property-selling',
      label: 'Comprar',
      placeholder: 'Escribe aquí ciudad, zona o barrio',
      url: BULDING_SELLING_PATH,
    },
    {
      id: 'project',
      label: 'Proyectos',
      placeholder: '¿Qué proyecto buscas?',
      url: BULDING_PROJECT_PATH,
    },
  ];

  connectedCallback() {
    super.connectedCallback();
    this.findSelectedOptionBasedOnPathname();
  }

  findSelectedOptionBasedOnPathname() {
    const basePath = document.location.pathname;
    const activeOption = this.options.find(option => {
      return basePath.startsWith(option.url);
    });

    this.selectedId = activeOption?.id || 'property-leasing';
  }

  getDefaultInputTextBasedOnSearch() {
    const search = document.location.search;
    const params = new URLSearchParams(search);
    const inputValue = params.get('search') || '';
    return inputValue;
  }

  getSelectedOptionInfo(id: string) {
    return this.options.find(option => option.id == id);
  }

  inputTemplate(): TemplateResult {
    const selectedOption = this.getSelectedOptionInfo(this.selectedId);
    const placeholderOption = selectedOption?.placeholder;

    return html`
      <form action=${selectedOption?.url}>
        <input
          type="text"
          class="input"
          placeholder=${placeholderOption}
          name="search"
          value=${this.getDefaultInputTextBasedOnSearch()}
        />
        <button type="submit" class="button">Buscar</button>
      </form>
    `;
  }

  render(): TemplateResult {
    return html` <div class="search">${this.inputTemplate()}</div> `;
  }
}
