import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-body-card.css.lit';

/**
 * The Coninsa Body Card component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-body-card')
export class CoBodyCard extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Visible.
   */
  @property({ attribute: 'visible', type: Boolean })
  visible = false;

  handleClick() {
    this.visible = false;
  }

  render(): TemplateResult {
    const classes = { 'card': true, 'card--visible': this.visible };

    return html`
      <div class=${classMap(classes)} target="_blank">
        <div class="actions">
          <button type="button" @click=${this.handleClick} class="close_btn">
            <outline-icon
              library="custom"
              size="30px"
              name="close-icon"
            ></outline-icon>
          </button>
        </div>
        <div class="content">
          <slot></slot>
        </div>
      </div>
    `;
  }
}
