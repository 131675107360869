
import { css } from 'lit';
export default css`
/* Apply CSS Variables to the host element. */
:host {
--outline-button--default--secondary-padding:var(--outline-button--default-padding);

--outline-button--default--secondary-radius:var(--outline-button--default-radius);

--outline-button--default--secondary-bg-color-default:var(--outline-white);

--outline-button--default--secondary-text-color-default:var(--outline-blue-600);

--outline-button--default--secondary-weight-default:var(--outline-button--default-weight);

--outline-button--default--secondary-ring-default:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-blue-600);

--outline-button--default--secondary-bg-color-hover:var(--outline-blue-600);

--outline-button--default--secondary-text-color-hover:var(--outline-white);

--outline-button--default--secondary-weight-hover:var(--outline-button--default-weight);

--outline-button--default--secondary-ring-hover:none;

--outline-button--default--secondary-bg-color-focus:var(--outline-blue-600);

--outline-button--default--secondary-text-color-focus:var(--outline-white);

--outline-button--default--secondary-weight-focus:var(--outline-button--default-weight);

--outline-button--default--secondary-ring-focus:none;

--outline-button--default--secondary--disabled-bg-color:var(--outline-white);

--outline-button--default--secondary--disabled-text-color:var(--outline-gray-400);

--outline-button--default--secondary--disabled-weight:var(--outline-button--default-weight);

--outline-button--default--secondary--disabled-ring:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-gray-300);

--outline-button--default--secondary--disabled-bg-color-focus:var(--outline-white);

--outline-button--default--secondary--disabled-text-color-focus:var(--outline-gray-400);

--outline-button--default--secondary--disabled-weight-focus:var(--outline-button--default-weight);

--outline-button--default--secondary--disabled-ring-focus:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-gray-400);
`;