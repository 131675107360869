
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  margin-bottom: 1rem;
  display: block;
  border-radius: 0.5rem;
  background-color: var(--neutral-white);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}@media (min-width: 768px) {:host {
    margin-bottom: 0px;
  }
}:host{
  box-shadow:0px 1px 4px #00000029;
}

.ad_card {
  display: grid;
  text-align: center;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  opacity: 1;
}
`;