
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  display: block;
}

.card {
  position: relative;
  display: block;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-color: var(--neutral-white);
  color: var(--gray-800);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  opacity: 1;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.info {
  box-sizing: border-box;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: var(--ff-display);
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
}

.type {
  margin-bottom: 0.25rem;
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  font-weight: var(--fw-normal);
  color: var(--brand-nonary);
}

.service {
  margin-bottom: 0.875rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

.code {
  margin-bottom: 0.75rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  font-weight: var(--fw-bold);
  color: var(--neutral-grayDark);
}

.area {
  margin-bottom: 0.75rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

.place {
  position: relative;
  margin-bottom: 0.875rem;
  height: 2.5rem;
  padding-left: 1.75rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

.svg-place {
  position: absolute;
  left: 0px;
}

.divider{
  border-top:1px solid #ddd;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.canon {
  display: flex;
  font-size: var(--fs-2xl);
  line-height: var(--lh-2xl);
  font-weight: var(--fw-medium);
}

.canon__inner {
  display: flex;
  flex-wrap: wrap;
}

.canon--scratched {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.canon--fair {
  margin-left: 0.5rem;
  color: var(--red-600);
}

.fair-text {
  margin-left: 0.5rem;
  color: var(--red-600);
}

.image {
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height:220px;
}

.image img {
  height: 100%;
}

.image img {
  width: 100%;
}

.image img {
  object-fit: cover;
}

.svg {
  margin-right: 0.25rem;
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: middle;
  color: var(--gray-600);
}

.canonSvg {
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  color: var(--gray-600);
}

.flags {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
}

.actions {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
}
`;