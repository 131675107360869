
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.result-tag {

    margin-bottom: 0.75rem
}

.tag {

    margin-right: 0.5rem;

    background-color: var(--gray-200);

    padding: 0.25rem;

    font-size: var(--fs-xs);

    line-height: var(--lh-xs);

    color: var(--gray-600);

    -webkit-text-decoration-line: none;

            text-decoration-line: none
}

.close-icon {

    height: 0.75rem;

    width: 0.75rem;

    vertical-align: middle
}

.clear-all {

    font-size: var(--fs-sm);

    line-height: var(--lh-sm);

    font-weight: var(--fw-light);

    color: var(--blue-500);

    -webkit-text-decoration-line: none;

            text-decoration-line: none
}

.clear-icon {

    margin-right: 0.25rem;

    height: 1rem;

    width: 1rem;

    vertical-align: baseline;

    color: var(--blue-500)
}
`;