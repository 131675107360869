import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-three-item-diagram.css.lit';

/**
 * The Coninsa Image Cap component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-three-item-diagram')
export class CoThreeItemDiagram extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Heading.
   */
  @property()
  heading: string;

  render(): TemplateResult {
    return html`
      <div class="three-item-diagram">
        <outline-heading level="h4" class="heading">
          ${this.heading}
        </outline-heading>

        <div class="content">
          <slot></slot>
        </div>
      </div>
    `;
  }
}
