
import { css } from 'lit';
export default css`
/* Apply CSS Variables to the host element. */
:host {
--outline--list-default-spacing:var(--spacing-4);

--outline--list-default-spacing-xs:var(--outline--list-default-spacing, var(--spacing-4));

--outline--list-default-spacing-sm:var(--outline--list-default-spacing, var(--spacing-4));

--outline--list-default-spacing-md:var(--outline--list-default-spacing, var(--spacing-4));

--outline--list-default-spacing-lg:var(--outline--list-default-spacing, var(--spacing-4));

--outline--list-default-spacing-xl:var(--outline--list-default-spacing, var(--spacing-4));

--outline--list-default-spacing-xxl:var(--outline--list-default-spacing, var(--spacing-4));

--outline--list-default-spacing-xxxl:var(--outline--list-default-spacing, var(--spacing-4));

`;