/**
 * This file is auto generated via npm run generate.resolved-tailwind
 */

export type Color =
  | 'outline-phase2-blue'
  | 'outline-karma-coral'
  | 'outline-soft-black'
  | 'outline-not-gray'
  | 'outline-misty-teal'
  | 'outline-electric-violet'
  | 'outline-dusty-blue'
  | 'outline-transparent'
  | 'outline-white'
  | 'outline-black'
  | 'neutral-transparent'
  | 'neutral-white'
  | 'neutral-grayLight'
  | 'neutral-grayMedium'
  | 'neutral-grayDark'
  | 'neutral-black'
  | 'coninsa-blue-300'
  | 'coninsa-blue-900'
  | 'coninsa-gray-300'
  | 'coninsa-gray-900'
  | 'coninsa-green-300'
  | 'coninsa-green-500'
  | 'coninsa-green-900'
  | 'coninsa-yellow-300'
  | 'coninsa-white-300'
  | 'coninsa-red-300'
  | 'brand-primary'
  | 'brand-secondary'
  | 'brand-tertiary'
  | 'brand-quaternary'
  | 'brand-quinary'
  | 'brand-senary'
  | 'brand-septenary'
  | 'brand-octonary'
  | 'brand-nonary'
  | 'brand-denary'
  | 'ui-error'
  | 'ui-warning'
  | 'ui-info'
  | 'ui-success'
  | 'outline-gray-50'
  | 'outline-gray-100'
  | 'outline-gray-200'
  | 'outline-gray-300'
  | 'outline-gray-400'
  | 'outline-gray-500'
  | 'outline-gray-600'
  | 'outline-gray-700'
  | 'outline-gray-800'
  | 'outline-gray-900'
  | 'outline-blue-50'
  | 'outline-blue-100'
  | 'outline-blue-200'
  | 'outline-blue-300'
  | 'outline-blue-400'
  | 'outline-blue-500'
  | 'outline-blue-600'
  | 'outline-blue-700'
  | 'outline-blue-800'
  | 'outline-blue-900'
  | 'gray-50'
  | 'gray-100'
  | 'gray-200'
  | 'gray-300'
  | 'gray-400'
  | 'gray-500'
  | 'gray-600'
  | 'gray-700'
  | 'gray-800'
  | 'gray-900'
  | 'blue-50'
  | 'blue-100'
  | 'blue-200'
  | 'blue-300'
  | 'blue-400'
  | 'blue-500'
  | 'blue-600'
  | 'blue-700'
  | 'blue-800'
  | 'blue-900'
  | 'red-50'
  | 'red-100'
  | 'red-200'
  | 'red-300'
  | 'red-400'
  | 'red-500'
  | 'red-600'
  | 'red-700'
  | 'red-800'
  | 'red-900'
  | 'yellow-50'
  | 'yellow-100'
  | 'yellow-200'
  | 'yellow-300'
  | 'yellow-400'
  | 'yellow-500'
  | 'yellow-600'
  | 'yellow-700'
  | 'yellow-800'
  | 'yellow-900'
  | 'green-50'
  | 'green-100'
  | 'green-200'
  | 'green-300'
  | 'green-400'
  | 'green-500'
  | 'green-600'
  | 'green-700'
  | 'green-800'
  | 'green-900'
  | 'indigo-50'
  | 'indigo-100'
  | 'indigo-200'
  | 'indigo-300'
  | 'indigo-400'
  | 'indigo-500'
  | 'indigo-600'
  | 'indigo-700'
  | 'indigo-800'
  | 'indigo-900'
  | 'purple-50'
  | 'purple-100'
  | 'purple-200'
  | 'purple-300'
  | 'purple-400'
  | 'purple-500'
  | 'purple-600'
  | 'purple-700'
  | 'purple-800'
  | 'purple-900'
  | 'pink-50'
  | 'pink-100'
  | 'pink-200'
  | 'pink-300'
  | 'pink-400'
  | 'pink-500'
  | 'pink-600'
  | 'pink-700'
  | 'pink-800'
  | 'pink-900';

export const ALL_COLORS: Color[] = [
  'outline-phase2-blue',
  'outline-karma-coral',
  'outline-soft-black',
  'outline-not-gray',
  'outline-misty-teal',
  'outline-electric-violet',
  'outline-dusty-blue',
  'outline-transparent',
  'outline-white',
  'outline-black',
  'neutral-transparent',
  'neutral-white',
  'neutral-grayLight',
  'neutral-grayMedium',
  'neutral-grayDark',
  'neutral-black',
  'coninsa-blue-300',
  'coninsa-blue-900',
  'coninsa-gray-300',
  'coninsa-gray-900',
  'coninsa-green-300',
  'coninsa-green-500',
  'coninsa-green-900',
  'coninsa-yellow-300',
  'coninsa-white-300',
  'coninsa-red-300',
  'brand-primary',
  'brand-secondary',
  'brand-tertiary',
  'brand-quaternary',
  'brand-quinary',
  'brand-senary',
  'brand-septenary',
  'brand-octonary',
  'brand-nonary',
  'brand-denary',
  'ui-error',
  'ui-warning',
  'ui-info',
  'ui-success',
  'outline-gray-50',
  'outline-gray-100',
  'outline-gray-200',
  'outline-gray-300',
  'outline-gray-400',
  'outline-gray-500',
  'outline-gray-600',
  'outline-gray-700',
  'outline-gray-800',
  'outline-gray-900',
  'outline-blue-50',
  'outline-blue-100',
  'outline-blue-200',
  'outline-blue-300',
  'outline-blue-400',
  'outline-blue-500',
  'outline-blue-600',
  'outline-blue-700',
  'outline-blue-800',
  'outline-blue-900',
  'gray-50',
  'gray-100',
  'gray-200',
  'gray-300',
  'gray-400',
  'gray-500',
  'gray-600',
  'gray-700',
  'gray-800',
  'gray-900',
  'blue-50',
  'blue-100',
  'blue-200',
  'blue-300',
  'blue-400',
  'blue-500',
  'blue-600',
  'blue-700',
  'blue-800',
  'blue-900',
  'red-50',
  'red-100',
  'red-200',
  'red-300',
  'red-400',
  'red-500',
  'red-600',
  'red-700',
  'red-800',
  'red-900',
  'yellow-50',
  'yellow-100',
  'yellow-200',
  'yellow-300',
  'yellow-400',
  'yellow-500',
  'yellow-600',
  'yellow-700',
  'yellow-800',
  'yellow-900',
  'green-50',
  'green-100',
  'green-200',
  'green-300',
  'green-400',
  'green-500',
  'green-600',
  'green-700',
  'green-800',
  'green-900',
  'indigo-50',
  'indigo-100',
  'indigo-200',
  'indigo-300',
  'indigo-400',
  'indigo-500',
  'indigo-600',
  'indigo-700',
  'indigo-800',
  'indigo-900',
  'purple-50',
  'purple-100',
  'purple-200',
  'purple-300',
  'purple-400',
  'purple-500',
  'purple-600',
  'purple-700',
  'purple-800',
  'purple-900',
  'pink-50',
  'pink-100',
  'pink-200',
  'pink-300',
  'pink-400',
  'pink-500',
  'pink-600',
  'pink-700',
  'pink-800',
  'pink-900',
];

export default {
  container: {
    center: true,
    screens: { sm: '100%', md: '100%', xl: '1152px' },
  },
  screens: {
    xs: '480px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1440px',
    xxxl: '2180px',
  },
  colors: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  fontSize: {
    'xs': ['var(--fs-xs)', { lineHeight: 'var(--lh-xs)' }],
    'sm': ['var(--fs-sm)', { lineHeight: 'var(--lh-sm)' }],
    'base': ['var(--fs-base)', { lineHeight: 'var(--lh-base)' }],
    'lg': ['var(--fs-lg)', { lineHeight: 'var(--lh-lg)' }],
    'xl': ['var(--fs-xl)', { lineHeight: 'var(--lh-xl)' }],
    '2xl': ['var(--fs-2xl)', { lineHeight: 'var(--lh-2xl)' }],
    '3xl': ['var(--fs-3xl)', { lineHeight: 'var(--lh-3xl)' }],
    '4xl': ['var(--fs-4xl)', { lineHeight: 'var(--lh-4xl)' }],
    '5xl': ['var(--fs-5xl)', { lineHeight: 'var(--lh-5xl)' }],
    '6xl': ['var(--fs-6xl)', { lineHeight: 'var(--lh-6xl)' }],
    '7xl': ['var(--fs-7xl)', { lineHeight: 'var(--lh-7xl)' }],
    '8xl': ['var(--fs-8xl)', { lineHeight: 'var(--lh-8xl)' }],
    '9xl': ['var(--fs-9xl)', { lineHeight: 'var(--lh-9lx)' }],
    'h1': ['var(--fs-h1)', { lineHeight: 'var(--lh-h1)' }],
    'h1-small': ['var(--fs-h1-small)', { lineHeight: 'var(--lh-h1-small)' }],
    'h1-medium': ['var(--fs-h1-medium)', { lineHeight: 'var(--lh-h1-medium)' }],
    'h2': ['var(--fs-h2)', { lineHeight: 'var(--lh-h2)' }],
    'h2-small': ['var(--fs-h2-small)', { lineHeight: 'var(--lh-h2-small)' }],
    'h2-medium': ['var(--fs-h2-medium)', { lineHeight: 'var(--lh-h2-medium)' }],
    'h3': ['var(--fs-h3)', { lineHeight: 'var(--lh-h3)' }],
    'h3-small': ['var(--fs-h3-small)', { lineHeight: 'var(--lh-h3-small)' }],
    'h3-medium': ['var(--fs-h3-medium)', { lineHeight: 'var(--lh-h3-medium)' }],
    'h4': ['var(--fs-h4)', { lineHeight: 'var(--lh-h4)' }],
    'h4-small': ['var(--fs-h4-small)', { lineHeight: 'var(--lh-h4-small)' }],
    'h4-medium': ['var(--fs-h4-medium)', { lineHeight: 'var(--lh-h4-medium)' }],
    'h5': ['var(--fs-h5)', { lineHeight: 'var(--lh-h5)' }],
    'h5-small': ['var(--fs-h5-small)', { lineHeight: 'var(--lh-h5-small)' }],
    'h5-medium': ['var(--fs-h5-medium)', { lineHeight: 'var(--lh-h5-medium)' }],
    'h6': ['var(--fs-h6)', { lineHeight: 'var(--lh-h6)' }],
    'h6-small': ['var(--fs-h6-small)', { lineHeight: 'var(--lh-h6-small)' }],
    'h6-medium': ['var(--fs-h6-medium)', { lineHeight: 'var(--lh-h6-medium)' }],
  },
  fontWeight: {
    thin: 'var(--fw-extralight)',
    extralight: 'var(--fw-thin)',
    light: 'var(--fw-light)',
    normal: 'var(--fw-normal)',
    medium: 'var(--fw-medium)',
    semibold: 'var(--fw-semibold)',
    bold: 'var(--fw-bold)',
    extrabold: 'var(--fw-extrabold)',
    black: 'var(--fw-black)',
  },
  columns: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    'auto': 'auto',
    '3xs': '16rem',
    '2xs': '18rem',
    'xs': '20rem',
    'sm': '24rem',
    'md': '28rem',
    'lg': '32rem',
    'xl': '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
  },
  spacing: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
  },
  animation: {
    none: 'none',
    spin: 'spin 1s linear infinite',
    ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
    pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
    bounce: 'bounce 1s infinite',
  },
  aspectRatio: { auto: 'auto', square: '1 / 1', video: '16 / 9' },
  backdropBlur: {
    '0': '0',
    'none': '0',
    'sm': '4px',
    'DEFAULT': '8px',
    'md': '12px',
    'lg': '16px',
    'xl': '24px',
    '2xl': '40px',
    '3xl': '64px',
  },
  backdropBrightness: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  backdropContrast: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '100': '1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  backdropGrayscale: { '0': '0', 'DEFAULT': '100%' },
  backdropHueRotate: {
    '0': '0deg',
    '15': '15deg',
    '30': '30deg',
    '60': '60deg',
    '90': '90deg',
    '180': '180deg',
  },
  backdropInvert: { '0': '0', 'DEFAULT': '100%' },
  backdropOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  backdropSaturate: {
    '0': '0',
    '50': '.5',
    '100': '1',
    '150': '1.5',
    '200': '2',
  },
  backdropSepia: { '0': '0', 'DEFAULT': '100%' },
  backgroundColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  backgroundImage: {
    'none': 'none',
    'gradient-to-t': 'linear-gradient(to top, var(--tw-gradient-stops))',
    'gradient-to-tr': 'linear-gradient(to top right, var(--tw-gradient-stops))',
    'gradient-to-r': 'linear-gradient(to right, var(--tw-gradient-stops))',
    'gradient-to-br':
      'linear-gradient(to bottom right, var(--tw-gradient-stops))',
    'gradient-to-b': 'linear-gradient(to bottom, var(--tw-gradient-stops))',
    'gradient-to-bl':
      'linear-gradient(to bottom left, var(--tw-gradient-stops))',
    'gradient-to-l': 'linear-gradient(to left, var(--tw-gradient-stops))',
    'gradient-to-tl': 'linear-gradient(to top left, var(--tw-gradient-stops))',
  },
  backgroundOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  backgroundPosition: {
    'bottom': 'bottom',
    'center': 'center',
    'left': 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    'right': 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    'top': 'top',
  },
  backgroundSize: { auto: 'auto', cover: 'cover', contain: 'contain' },
  blur: {
    '0': '0',
    'none': '0',
    'sm': '4px',
    'DEFAULT': '8px',
    'md': '12px',
    'lg': '16px',
    'xl': '24px',
    '2xl': '40px',
    '3xl': '64px',
  },
  brightness: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  borderColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
    'DEFAULT': 'var(--gray-200)',
  },
  borderOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  borderRadius: {
    'none': '0px',
    'sm': '0.125rem',
    'DEFAULT': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  borderWidth: {
    '0': '0px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'DEFAULT': '1px',
  },
  boxShadow: {
    'sm': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    'DEFAULT': '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    'md': '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    'lg': '0px 8px 32px rgba(0, 0, 0, 0.06)',
    'xl': '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
    'inner': 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
    'none': 'none',
    'focus': '0 0 0 1px rgb(33, 74, 222)',
    'focus-2': '0 0 0 2px rgb(33, 74, 222)',
    'focus-error': '0 0 0 1px rgb(225, 9, 9)',
  },
  boxShadowColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  caretColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  accentColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
    'auto': 'auto',
  },
  contrast: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '100': '1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  content: { none: 'none' },
  cursor: {
    'auto': 'auto',
    'default': 'default',
    'pointer': 'pointer',
    'wait': 'wait',
    'text': 'text',
    'move': 'move',
    'help': 'help',
    'not-allowed': 'not-allowed',
    'none': 'none',
    'context-menu': 'context-menu',
    'progress': 'progress',
    'cell': 'cell',
    'crosshair': 'crosshair',
    'vertical-text': 'vertical-text',
    'alias': 'alias',
    'copy': 'copy',
    'no-drop': 'no-drop',
    'grab': 'grab',
    'grabbing': 'grabbing',
    'all-scroll': 'all-scroll',
    'col-resize': 'col-resize',
    'row-resize': 'row-resize',
    'n-resize': 'n-resize',
    'e-resize': 'e-resize',
    's-resize': 's-resize',
    'w-resize': 'w-resize',
    'ne-resize': 'ne-resize',
    'nw-resize': 'nw-resize',
    'se-resize': 'se-resize',
    'sw-resize': 'sw-resize',
    'ew-resize': 'ew-resize',
    'ns-resize': 'ns-resize',
    'nesw-resize': 'nesw-resize',
    'nwse-resize': 'nwse-resize',
    'zoom-in': 'zoom-in',
    'zoom-out': 'zoom-out',
  },
  divideColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
    'DEFAULT': 'var(--gray-200)',
  },
  divideOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  divideWidth: {
    '0': '0px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'DEFAULT': '1px',
  },
  dropShadow: {
    'sm': '0 1px 1px rgb(0 0 0 / 0.05)',
    'DEFAULT': ['0 1px 2px rgb(0 0 0 / 0.1)', '0 1px 1px rgb(0 0 0 / 0.06)'],
    'md': ['0 4px 3px rgb(0 0 0 / 0.07)', '0 2px 2px rgb(0 0 0 / 0.06)'],
    'lg': ['0 10px 8px rgb(0 0 0 / 0.04)', '0 4px 3px rgb(0 0 0 / 0.1)'],
    'xl': ['0 20px 13px rgb(0 0 0 / 0.03)', '0 8px 5px rgb(0 0 0 / 0.08)'],
    '2xl': '0 25px 25px rgb(0 0 0 / 0.15)',
    'none': '0 0 #0000',
  },
  fill: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  grayscale: { '0': '0', 'DEFAULT': '100%' },
  hueRotate: {
    '0': '0deg',
    '15': '15deg',
    '30': '30deg',
    '60': '60deg',
    '90': '90deg',
    '180': '180deg',
  },
  invert: { '0': '0', 'DEFAULT': '100%' },
  flex: {
    '1': '1 1 0%',
    'auto': '1 1 auto',
    'initial': '0 1 auto',
    'none': 'none',
  },
  flexBasis: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    'full': '100%',
  },
  flexGrow: { '0': '0', 'DEFAULT': '1' },
  flexShrink: { '0': '0', 'DEFAULT': '1' },
  fontFamily: {
    sans: [
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    serif: [
      'ui-serif',
      'Georgia',
      'Cambria',
      '"Times New Roman"',
      'Times',
      'serif',
    ],
    mono: [
      'ui-monospace',
      'SFMono-Regular',
      'Menlo',
      'Monaco',
      'Consolas',
      '"Liberation Mono"',
      '"Courier New"',
      'monospace',
    ],
    display: ['var(--ff-display)'],
    body: ['var(--ff-body)'],
    demo: ['var(--ff-demo)'],
  },
  gap: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
  },
  gradientColorStops: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  gridAutoColumns: {
    auto: 'auto',
    min: 'min-content',
    max: 'max-content',
    fr: 'minmax(0, 1fr)',
  },
  gridAutoRows: {
    auto: 'auto',
    min: 'min-content',
    max: 'max-content',
    fr: 'minmax(0, 1fr)',
  },
  gridColumn: {
    'auto': 'auto',
    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-7': 'span 7 / span 7',
    'span-8': 'span 8 / span 8',
    'span-9': 'span 9 / span 9',
    'span-10': 'span 10 / span 10',
    'span-11': 'span 11 / span 11',
    'span-12': 'span 12 / span 12',
    'span-full': '1 / -1',
  },
  gridColumnEnd: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    'auto': 'auto',
  },
  gridColumnStart: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    'auto': 'auto',
  },
  gridRow: {
    'auto': 'auto',
    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-full': '1 / -1',
  },
  gridRowStart: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    'auto': 'auto',
  },
  gridRowEnd: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    'auto': 'auto',
  },
  gridTemplateColumns: {
    '1': 'repeat(1, minmax(0, 1fr))',
    '2': 'repeat(2, minmax(0, 1fr))',
    '3': 'repeat(3, minmax(0, 1fr))',
    '4': 'repeat(4, minmax(0, 1fr))',
    '5': 'repeat(5, minmax(0, 1fr))',
    '6': 'repeat(6, minmax(0, 1fr))',
    '7': 'repeat(7, minmax(0, 1fr))',
    '8': 'repeat(8, minmax(0, 1fr))',
    '9': 'repeat(9, minmax(0, 1fr))',
    '10': 'repeat(10, minmax(0, 1fr))',
    '11': 'repeat(11, minmax(0, 1fr))',
    '12': 'repeat(12, minmax(0, 1fr))',
    'none': 'none',
    '1-2': '4fr 8fr',
    '2-1': '8fr 4fr',
  },
  gridTemplateRows: {
    '1': 'repeat(1, minmax(0, 1fr))',
    '2': 'repeat(2, minmax(0, 1fr))',
    '3': 'repeat(3, minmax(0, 1fr))',
    '4': 'repeat(4, minmax(0, 1fr))',
    '5': 'repeat(5, minmax(0, 1fr))',
    '6': 'repeat(6, minmax(0, 1fr))',
    'none': 'none',
  },
  height: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    'full': '100%',
    'screen': '100vh',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
    'full-screen-w': '100vw',
    'half-screen-w': '50vw',
    'quarter-screen-w': '25vw',
    'full-screen-h': '100vh',
    'half-screen-h': '50vh',
    'quarter-screen-h': '25vh',
  },
  inset: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    'full': '100%',
  },
  keyframes: {
    spin: { to: { transform: 'rotate(360deg)' } },
    ping: { '75%, 100%': { transform: 'scale(2)', opacity: '0' } },
    pulse: { '50%': { opacity: '.5' } },
    bounce: {
      '0%, 100%': {
        transform: 'translateY(-25%)',
        animationTimingFunction: 'cubic-bezier(0.8,0,1,1)',
      },
      '50%': {
        transform: 'none',
        animationTimingFunction: 'cubic-bezier(0,0,0.2,1)',
      },
    },
  },
  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0em',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeight: {
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    'none': '1',
    'tight': '1.25',
    'snug': '1.375',
    'normal': '1.5',
    'relaxed': '1.625',
    'loose': '2',
  },
  listStyleType: { none: 'none', disc: 'disc', decimal: 'decimal' },
  margin: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
  },
  maxHeight: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'full': '100%',
    'screen': '100vh',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
    'full-screen-w': '100vw',
    'half-screen-w': '50vw',
    'quarter-screen-w': '25vw',
    'full-screen-h': '100vh',
    'half-screen-h': '50vh',
    'quarter-screen-h': '25vh',
  },
  maxWidth: {
    '0': '0rem',
    'none': 'none',
    'xs': '20rem',
    'sm': '24rem',
    'md': '28rem',
    'lg': '32rem',
    'xl': '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    'full': '100%',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
    'prose': '65ch',
    'screen-xs': '480px',
    'screen-sm': '640px',
    'screen-md': '768px',
    'screen-lg': '1024px',
    'screen-xl': '1280px',
    'screen-xxl': '1440px',
    'screen-xxxl': '2180px',
    'full-screen-w': '100vw',
    'half-screen-w': '50vw',
    'quarter-screen-w': '25vw',
    'full-screen-h': '100vh',
    'half-screen-h': '50vh',
    'quarter-screen-h': '25vh',
  },
  minHeight: {
    '0': '0px',
    'full': '100%',
    'screen': '100vh',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
  },
  minWidth: {
    '0': '0px',
    'full': '100%',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
  },
  objectPosition: {
    'bottom': 'bottom',
    'center': 'center',
    'left': 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    'right': 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    'top': 'top',
  },
  opacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  order: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    'first': '-9999',
    'last': '9999',
    'none': '0',
  },
  padding: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
  },
  placeholderColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  placeholderOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  outlineColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  outlineOffset: { '0': '0px', '1': '1px', '2': '2px', '4': '4px', '8': '8px' },
  outlineWidth: { '0': '0px', '1': '1px', '2': '2px', '4': '4px', '8': '8px' },
  ringColor: {
    'DEFAULT': 'var(--blue-500)',
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  ringOffsetColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  ringOffsetWidth: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
  },
  ringOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
    'DEFAULT': '0.5',
  },
  ringWidth: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'DEFAULT': '3px',
  },
  rotate: {
    '0': '0deg',
    '1': '1deg',
    '2': '2deg',
    '3': '3deg',
    '6': '6deg',
    '12': '12deg',
    '45': '45deg',
    '90': '90deg',
    '180': '180deg',
  },
  saturate: { '0': '0', '50': '.5', '100': '1', '150': '1.5', '200': '2' },
  scale: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
  },
  scrollMargin: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
  },
  scrollPadding: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
  },
  sepia: { '0': '0', 'DEFAULT': '100%' },
  skew: {
    '0': '0deg',
    '1': '1deg',
    '2': '2deg',
    '3': '3deg',
    '6': '6deg',
    '12': '12deg',
  },
  space: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
  },
  stroke: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  strokeWidth: { '0': '0', '1': '1', '2': '2' },
  textColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  textDecorationColor: {
    'outline': {
      'phase2-blue': 'var(--outline-phase2-blue)',
      'karma-coral': 'var(--outline-karma-coral)',
      'soft-black': 'var(--outline-soft-black)',
      'not-gray': 'var(--outline-not-gray)',
      'misty-teal': 'var(--outline-misty-teal)',
      'electric-violet': 'var(--outline-electric-violet)',
      'dusty-blue': 'var(--outline-dusty-blue)',
      'transparent': 'var(--outline-transparent)',
      'white': 'var(--outline-white)',
      'black': 'var(--outline-black)',
    },
    'neutral': {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    'coninsa': {
      blue: {
        '300': 'var(--coninsa-blue-300)',
        '900': 'var(--coninsa-blue-900)',
      },
      gray: {
        '300': 'var(--coninsa-gray-300)',
        '900': 'var(--coninsa-gray-900)',
      },
      green: {
        '300': 'var(--coninsa-green-300)',
        '500': 'var(--coninsa-green-500)',
        '900': 'var(--coninsa-green-900)',
      },
      yellow: { '300': 'var(--coninsa-yellow-300)' },
      white: { '300': 'var(--coninsa-white-300)' },
      red: { '300': 'var(--coninsa-red-300)' },
    },
    'brand': {
      primary: 'var(--brand-primary)',
      secondary: 'var(--brand-secondary)',
      tertiary: 'var(--brand-tertiary)',
      quaternary: 'var(--brand-quaternary)',
      quinary: 'var(--brand-quinary)',
      senary: 'var(--brand-senary)',
      septenary: 'var(--brand-septenary)',
      octonary: 'var(--brand-octonary)',
      nonary: 'var(--brand-nonary)',
      denary: 'var(--brand-denary)',
    },
    'ui': {
      error: 'var(--status-error)',
      warning: 'var(--status-warning)',
      info: 'var(--status-info)',
      success: 'var(--status-success)',
    },
    'outline-gray': {
      '50': 'var(--outline-gray-50)',
      '100': 'var(--outline-gray-100)',
      '200': 'var(--outline-gray-200)',
      '300': 'var(--outline-gray-300)',
      '400': 'var(--outline-gray-400)',
      '500': 'var(--outline-gray-500)',
      '600': 'var(--outline-gray-600)',
      '700': 'var(--outline-gray-700)',
      '800': 'var(--outline-gray-800)',
      '900': 'var(--outline-gray-900)',
    },
    'outline-blue': {
      '50': 'var(--outline-blue-50)',
      '100': 'var(--outline-blue-100)',
      '200': 'var(--outline-blue-200)',
      '300': 'var(--outline-blue-300)',
      '400': 'var(--outline-blue-400)',
      '500': 'var(--outline-blue-500)',
      '600': 'var(--outline-blue-600)',
      '700': 'var(--outline-blue-700)',
      '800': 'var(--outline-blue-800)',
      '900': 'var(--outline-blue-900)',
    },
    'gray': {
      '50': 'var(--gray-50)',
      '100': 'var(--gray-100)',
      '200': 'var(--gray-200)',
      '300': 'var(--gray-300)',
      '400': 'var(--gray-400)',
      '500': 'var(--gray-500)',
      '600': 'var(--gray-600)',
      '700': 'var(--gray-700)',
      '800': 'var(--gray-800)',
      '900': 'var(--gray-900)',
    },
    'blue': {
      '50': 'var(--blue-50)',
      '100': 'var(--blue-100)',
      '200': 'var(--blue-200)',
      '300': 'var(--blue-300)',
      '400': 'var(--blue-400)',
      '500': 'var(--blue-500)',
      '600': 'var(--blue-600)',
      '700': 'var(--blue-700)',
      '800': 'var(--blue-800)',
      '900': 'var(--blue-900)',
    },
    'red': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'yellow': {
      '50': 'var(--yellow-50)',
      '100': 'var(--yellow-100)',
      '200': 'var(--yellow-200)',
      '300': 'var(--yellow-300)',
      '400': 'var(--yellow-400)',
      '500': 'var(--yellow-500)',
      '600': 'var(--yellow-600)',
      '700': 'var(--yellow-700)',
      '800': 'var(--yellow-800)',
      '900': 'var(--yellow-900)',
    },
    'green': {
      '50': 'var(--green-50)',
      '100': 'var(--green-100)',
      '200': 'var(--green-200)',
      '300': 'var(--green-300)',
      '400': 'var(--green-400)',
      '500': 'var(--green-500)',
      '600': 'var(--green-600)',
      '700': 'var(--green-700)',
      '800': 'var(--green-800)',
      '900': 'var(--green-900)',
    },
    'indigo': {
      '50': 'var(--red-50)',
      '100': 'var(--red-100)',
      '200': 'var(--red-200)',
      '300': 'var(--red-300)',
      '400': 'var(--red-400)',
      '500': 'var(--red-500)',
      '600': 'var(--red-600)',
      '700': 'var(--red-700)',
      '800': 'var(--red-800)',
      '900': 'var(--red-900)',
    },
    'purple': {
      '50': 'var(--purple-50)',
      '100': 'var(--purple-100)',
      '200': 'var(--purple-200)',
      '300': 'var(--purple-300)',
      '400': 'var(--purple-400)',
      '500': 'var(--purple-500)',
      '600': 'var(--purple-600)',
      '700': 'var(--purple-700)',
      '800': 'var(--purple-800)',
      '900': 'var(--purple-900)',
    },
    'pink': {
      '50': 'var(--pink-50)',
      '100': 'var(--pink-100)',
      '200': 'var(--pink-200)',
      '300': 'var(--pink-300)',
      '400': 'var(--pink-400)',
      '500': 'var(--pink-500)',
      '600': 'var(--pink-600)',
      '700': 'var(--pink-700)',
      '800': 'var(--pink-800)',
      '900': 'var(--pink-900)',
    },
  },
  textDecorationThickness: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'auto': 'auto',
    'from-font': 'from-font',
  },
  textUnderlineOffset: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'auto': 'auto',
  },
  textIndent: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
  },
  textOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  transformOrigin: {
    'center': 'center',
    'top': 'top',
    'top-right': 'top right',
    'right': 'right',
    'bottom-right': 'bottom right',
    'bottom': 'bottom',
    'bottom-left': 'bottom left',
    'left': 'left',
    'top-left': 'top left',
  },
  transitionDelay: {
    '75': '75ms',
    '100': '100ms',
    '150': '150ms',
    '200': '200ms',
    '300': '300ms',
    '500': '500ms',
    '700': '700ms',
    '1000': '1000ms',
  },
  transitionDuration: {
    '75': '75ms',
    '100': '100ms',
    '150': '150ms',
    '200': '200ms',
    '300': '300ms',
    '500': '500ms',
    '700': '700ms',
    '1000': '1000ms',
    'DEFAULT': '150ms',
  },
  transitionProperty: {
    none: 'none',
    all: 'all',
    DEFAULT:
      'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
    colors:
      'color, background-color, border-color, text-decoration-color, fill, stroke',
    opacity: 'opacity',
    shadow: 'box-shadow',
    transform: 'transform',
  },
  transitionTimingFunction: {
    'DEFAULT': 'cubic-bezier(0.4, 0, 0.2, 1)',
    'linear': 'linear',
    'in': 'cubic-bezier(0.4, 0, 1, 1)',
    'out': 'cubic-bezier(0, 0, 0.2, 1)',
    'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  translate: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    'full': '100%',
  },
  width: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    'full': '100%',
    'screen': '100vw',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
    'full-screen-w': '100vw',
    'half-screen-w': '50vw',
    'quarter-screen-w': '25vw',
    'full-screen-h': '100vh',
    'half-screen-h': '50vh',
    'quarter-screen-h': '25vh',
  },
  willChange: {
    auto: 'auto',
    scroll: 'scroll-position',
    contents: 'contents',
    transform: 'transform',
  },
  zIndex: {
    '0': '0',
    '10': '10',
    '20': '20',
    '30': '30',
    '40': '40',
    '50': '50',
    'auto': 'auto',
  },
};
