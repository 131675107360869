import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-search-header.css.lit';
import { classMap } from 'lit-html/directives/class-map.js';
import '../../base/outline-icon/outline-icon';

/**
 * The Coninsa search header component
 */
@customElement('co-search-header')
export class CoSearchHeader extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Heading text.
   */
  @property({ type: String })
  heading: string;

  render(): TemplateResult {
    const classes = {
      header: true,
    };

    return html`
      <div class=${classMap(classes)}>
        <h2 class="header__heading">${this.heading}</h2>

        <div class="header__content">
          <div class="header__summary">
            <slot name="summary"></slot>
          </div>
          <div class="header__sort">
            <slot name="sort"></slot>
          </div>
        </div>

        <div class="header__divider"></div>
      </div>
    `;
  }
}
