import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import { CoFacet } from '../co-facet/co-facet';
import componentStyles from './co-active-filters.css.lit';

import {
  BULDING_LEASING_PATH,
  BULDING_SELLING_PATH,
  BULDING_PROJECT_PATH,
} from '../config';

type Filter = {
  url: string;
  name: string;
  value: string;
};

/**
 * The Coninsa Active Filters component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-active-filters')
export class CoActiveFilters extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Result name
   */
  @property({ type: String })
  name: string;

  getActiveFilters() {
    const filters = document.querySelectorAll('co-facet .is-active');
    console.log(filters);
    const activeFilters = Array.prototype.map.call<
      NodeListOf<Element>,
      any[],
      Filter[]
    >(filters, function (filter: HTMLAnchorElement) {
      const coFacet: CoFacet | null = filter.closest('co-facet');
      const value = filter.querySelector('.facet-item__value')?.textContent;
      const url = filter.href;

      return {
        url,
        value,
        name: coFacet?.name,
      };
    });

    return activeFilters;
  }

  getBasePath() {
    const basePath = document.location.pathname;
    const baseMap = [
      BULDING_LEASING_PATH,
      BULDING_SELLING_PATH,
      BULDING_PROJECT_PATH,
    ];
    const activePath = baseMap.find(path => {
      return basePath.startsWith(path);
    });

    return activePath || BULDING_LEASING_PATH;
  }

  filterTemplate(filter: Filter): TemplateResult {
    let text = filter.value;

    if (parseInt(filter.value)) {
      text = `${filter.name}: ${filter.value}`;
    }

    return html`
      <a class="tag" href=${filter.url}>
        ${text}
        <svg
          class="close-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="5"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </a>
    `;
  }

  clearAllTemplate(): TemplateResult {
    const path = this.getBasePath();
    return html`<a class="clear-all" href=${path}>
      <svg
        class="clear-icon"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="3 6 5 6 21 6" />
        <path
          d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
        />
        <line x1="10" y1="11" x2="10" y2="17" />
        <line x1="14" y1="11" x2="14" y2="17" />
      </svg>
      Limpiar todos los filtros
    </a>`;
  }

  render(): TemplateResult {
    const filters = this.getActiveFilters();

    return html`
      <div class="result">
        <div class="result-tag">
          ${filters.map(filter => this.filterTemplate(filter))}
        </div>
        <div>${filters.length > 1 ? this.clearAllTemplate() : null}</div>
      </div>
    `;
  }
}
