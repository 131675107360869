import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-project-card.css.lit';
import defaultImageUrl from './assets/default-image.png';

/**
 * The Coninsa Card component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-project-card')
export class CoProjectCard extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * State
   */
  @property({ type: String, attribute: 'value' })
  state: 'Ninguno';

  /**
   * Property type.
   */
  @property()
  type: string;

  /**
   * Code.
   */
  @property()
  code: string;

  /**
   * Area.
   */
  @property()
  area: string;

  /**
   * Location.
   */
  @property()
  location: string;

  /**
   * Price symbol.
   */
  @property({ attribute: 'price-symbol' })
  priceSymbol: string;

  /**
   * Price from.
   */
  @property({ attribute: 'price-from' })
  priceFrom: number;

  /**
   * Price to.
   */
  @property({ attribute: 'price-to' })
  priceTo: number;

  /**
   * custom note.
   */
  @property({ attribute: 'custom-note' })
  customNote: string;

  /**
   * Brand.
   */
  @property()
  brandUrl: string;

  /**
   * Url.
   */
  @property()
  url: string;

  /**
   * Image.
   */
  @property()
  imageUrl?: string;

  /**
   * In release
   */
  @property({ type: Boolean })
  inRelease = false;

  /**
   * Last stage
   */
  @property({ type: Boolean })
  lastStage = false;

  /**
   * Has discount
   */
  @property({ type: Boolean })
  hasDiscount = false;

  /**
   * Is VIS
   */
  @property({ type: Boolean, attribute: 'is-vis' })
  isVis = false;

  formatPrice(price: number) {
    const formatedPrice = new Intl.NumberFormat('co', {
      style: 'currency',
      currency: 'COP',
    }).format(price);

    return formatedPrice
      .slice(0, -3)
      .replace('COP', this.priceSymbol)
      .replace(/,/g, '.');
  }

  getCanon() {
    return `Desde ${this.formatPrice(this.priceFrom)} hasta ${this.formatPrice(
      this.priceTo
    )}`;
  }

  visTemplate(): TemplateResult {
    return html`
      ${this.isVis
        ? html` <co-outstanding variant="senary">VIS</co-outstanding>`
        : null}
    `;
  }

  flagsTemplate(): TemplateResult {
    return html`
      <div class="flags">
        ${this.inRelease
          ? html` <co-outstanding variant="quaternary"
              >En lanzamiento</co-outstanding
            >`
          : null}
        ${this.lastStage
          ? html` <co-outstanding variant="quinary"
              >Última etapa</co-outstanding
            >`
          : null}
        ${this.hasDiscount
          ? html` <co-outstanding variant="secondary"
              >En descuento</co-outstanding
            >`
          : null}
      </div>
    `;
  }

  actionsTemplate(): TemplateResult {
    return html`
      <div class="actions">
        <slot></slot>
      </div>
    `;
  }

  render(): TemplateResult {
    const imagePropertyUrl = this?.imageUrl || defaultImageUrl;

    return html`
      <a class="card" href=${this.url} target="_blank">
        <div class="image">
          <img src=${imagePropertyUrl} />
          ${this.flagsTemplate()}
        </div>

        <div class="info">
          <div class="details">
            <div class="body">
              <div class="tags">
                <slot name="tags"></slot>
                ${this.visTemplate()}
              </div>
              <div class="type">${this.type}</div>
              <div class="code">Código: ${this.code}</div>
              <div class="area">
                <svg
                  class="svg"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 14h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 19h3v-2H5v-2H3v3a1 1 0 0 0 1 1zM19 4a1 1 0 0 0-1-1h-3v2h2v2h2V4zM5 5h2V3H4a1 1 0 0 0-1 1v3h2V5zM3 9h2v4H3zm14 0h2v3h-2zM9 3h4v2H9zm0 14h3v2H9z"
                    stroke="none"
                  />
                </svg>
                Área: ${this.area}
              </div>
              <div class="place">
                <svg
                  class="svg"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 14c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
                    stroke="none"
                  />
                  <path
                    d="M11.42 21.814a.998.998 0 0 0 1.16 0C12.884 21.599 20.029 16.44 20 10c0-4.411-3.589-8-8-8S4 5.589 4 9.995c-.029 6.445 7.116 11.604 7.42 11.819zM12 4c3.309 0 6 2.691 6 6.005.021 4.438-4.388 8.423-6 9.73-1.611-1.308-6.021-5.294-6-9.735 0-3.309 2.691-6 6-6z"
                    stroke="none"
                  />
                </svg>
                ${this.location}
              </div>

              <div class="divider"></div>
              <div class="canon">
                <svg
                  class="canonSvg"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="416"
                    height="288"
                    x="48"
                    y="144"
                    fill="none"
                    stroke-linejoin="round"
                    stroke-width="32"
                    rx="48"
                    ry="48"
                  />
                  <path
                    fill="none"
                    stroke-linejoin="round"
                    stroke-width="32"
                    d="M411.36 144v-30A50 50 0 0 0 352 64.9L88.64 109.85A50 50 0 0 0 48 159v49"
                  />
                  <path
                    d="M368 320a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"
                    stroke="none"
                  />
                </svg>
                <div>
                  ${this.getCanon()}
                  <div class="custom-note">${this.customNote}</div>
                </div>
              </div>
            </div>
            <div class="vertical-divider"></div>
            <div class="brand">
              <img src=${this.brandUrl} />
            </div>
          </div>
        </div>
        ${this.actionsTemplate()}
      </a>
    `;
  }
}
