import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-search-query.css.lit';

export type ControlType = 'default' | 'inline';
export type ControlSize = 'small' | 'medium' | 'large';

/**
 * The Control component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-search-query')
export class CoSearchQuery extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Variant type.
   */
  @property()
  label: string;

  _handleClick = (e: Event) => {
    e.preventDefault();

    const anchor = e.target;

    if (anchor) {
      const href = (anchor as HTMLAnchorElement).getAttribute('href') || '';
      const [key, value] = href.replace('?', '').split('=');

      const search = document.location.search;
      const params = new URLSearchParams(search);

      // Update sort operation param.
      params.set(key, value);

      // Redirect to new path.
      window.location.replace(
        `${document.location.pathname}?${params.toString()}`
      );
    }
  };

  render(): TemplateResult {
    return html`
      <div class="search-query" @click=${this._handleClick}>
        <slot></slot>
      </div>
    `;
  }
}
