import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-search-button.css.lit';
import { classMap } from 'lit-html/directives/class-map.js';
import '../../base/outline-icon/outline-icon';

/**
 * The Coninsa search button component
 */
@customElement('co-search-button')
export class CoSearchButton extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  render(): TemplateResult {
    const classes = {
      button: true,
    };

    return html`
      <div class=${classMap(classes)}>
        <div class="button">
          <div class="button__title">¿Tienes un código de inmueble?</div>
          <button class="button__label">Ingresa el código</button>
        </div>
      </div>
    `;
  }
}
