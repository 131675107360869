import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-featured-item.css.lit';

/**
 * The Coninsa Featured Item component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-featured-item')
export class CoFeaturedItem extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Icon Url.
   */
  @property({ attribute: 'icon-url' })
  iconUrl: string;

  /**
   * Label.
   */
  @property()
  label: string;

  render(): TemplateResult {
    return html`
      <div class="featured-item">
        <div class="content">
          <img class="icon" src=${this.iconUrl} />
          <div class="label">${this.label}</div>
        </div>
        <div slot class="divider"></div>
      </div>
    `;
  }
}
