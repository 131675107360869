
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
#overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--outline-black);
  --tw-bg-opacity: 0.6;
  background-color:rgb(0, 0, 0, var(--tw-bg-opacity));
}

#container {
  height: 100vh;
  max-width: 100%;
  overflow-y: auto;
  border-radius: 0.125rem;
  background-color: var(--outline-white);
}

@media (min-width: 768px) {

  #container {
    height: auto;
  }

  #container {
    max-width: 25vw;
  }
}

#container{
  min-width:320px;
}

@media (min-width: 768px) {

  #overlay.medium #container {
    max-width: 50vw;
  }
}

#overlay.full-screen #container {
  height: 100vh;
}

#overlay.full-screen #container {
  width: 100vw;
}

#overlay.full-screen #container {
  max-width: 100vw;
}

@media (min-width: 768px) {

  #overlay.full-screen #container {
    width: 100vw;
  }
}

@media (min-width: 768px) {

  #overlay.full-screen #container {
    max-width: 100vw;
  }
}

#header {
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}

#close {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  border-width: 0px;
  background-color: var(--outline-transparent);
}

#close:before{
  content:'\\2715';
}

#main {
  padding: 1.5rem;
}

#accessibility-description {
  display: none;
}
`;