
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.open{
}

.container {

  z-index: 10;

  display: none;

  width: auto;

  overflow-y: auto;

  padding-left: 1.25rem;

  padding-right: 1.25rem
}

.open .container {

  position: absolute;

  top: 0px;

  right: 0px;

  bottom: 0px;

  left: 0px;

  display: block;

  background-color: var(--neutral-white)
}

.actions {

  width: 100%;
}

.actions co-button {

  margin-bottom: 0.75rem
}
`;