
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  display: block;
}

.search {
  display: flex;
}

form {
  position: relative;
  display: flex;
  flex: 1 1 0%;
}

.input {
  box-sizing: border-box;
  height: 3rem;
  width: 100%;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray-300);
  padding: 0.75rem;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.button {
  right: 0px;
  height: 3rem;
  width: 2.5rem;
  cursor: pointer;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-style: none;
  background-color: var(--blue-700);
  background-position: center;
  background-repeat: no-repeat;
  color: var(--neutral-transparent);
  background-image:url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 16 16' height='25px' width='25px' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z'%3E%3C/path%3E%3C/svg%3E%0A");
}

.button:focus{
    outline:none;
  }
`;