import { html, TemplateResult, CSSResultGroup } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-search-bar.css.lit';
import { isMobile } from './utils';

/**
 * The Coninsa Search Bar component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-search-bar')
export class CoSearchBar extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Facet name
   */
  @property({ type: String })
  isOpen = false;

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  render(): TemplateResult {
    const classes = classMap({
      open: this.isOpen,
    });

    if (!isMobile()) {
      return html` <slot></slot> `;
    }

    console.log(this.isOpen);
    return html`
      <div class=${classes}>
        <co-button @click=${this.open} variant="primary"
          >Filtrar búsqueda</co-button
        >
        <div class="container">
          <slot></slot>
          <div class="actions">
            <co-button type="submit" variant="primary" rounded="true"
              >Aplicar filtros</co-button
            >
            <co-button @click=${this.close} variant="outline" rounded="true"
              >Cerrar</co-button
            >
          </div>
        </div>
      </div>
    `;
  }
}
