import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-tag.css.lit';

/**
 * The Coninsa Tag component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-tag')
export class CoTag extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Label.
   */
  @property()
  label: string;

  render(): TemplateResult {
    return html` <a class="tag">${this.label}</a> `;
  }
}
