
import { css } from 'lit';
export default css`
/* Apply CSS Variables to the host element. */
:host {
--outline-button--default-family:var(--ff-body);

--outline-button--default-weight:var(--fw-semibold);

--outline-button--default-padding:var(--spacing-2) var(--spacing-4);

--outline-button--default-radius:var(--spacing-2);

`;