import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-image-cap.css.lit';

/**
 * The Coninsa Image Cap component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-image-cap')
export class CoImageCap extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Image Url.
   */
  @property({ attribute: 'image-url' })
  ImageUrl: string;

  /**
   * Label.
   */
  @property()
  label: string;

  render(): TemplateResult {
    return html`
      <div class="image-cap">
        <img class="image" src=${this.ImageUrl} />
        <div class="label">${this.label}</div>
      </div>
    `;
  }
}
