
import { css } from 'lit';
export default css`
/* Apply CSS Variables to the host element. */
:host {
--outline--link-default-transition-property:color, background-color, border-color, text-decoration-color, fill, stroke;

--outline--link-default-transition-duration:150ms;

--outline--link-default-transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);

--outline--link-default-color-default:var(--outline-blue-600);

--outline--link-default-color-default-decoration:none;

--outline--link-default-color-hover:var(--outline--gray-600);

--outline--link-default-color-hover-decoration:underline;

--outline--link-default-color-focus:var(--outline--gray-600);

--outline--link-default-color-focus-decoration:underline;
`;