
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

  display: block
}

@media (min-width: 768px) {

  :host {

    display: inline-block
  }
}

.button {

  display: block;

  width: 100%;

  min-width: 7.125rem;

  cursor: pointer;

  border-color: var(--neutral-transparent);

  padding-left: 1rem;

  padding-right: 1rem;

  padding-top: 0.75rem;

  padding-bottom: 0.75rem;

  font-family: var(--ff-body)
}

.primary {

  background-color: var(--yellow-400)
}

.secondary {

  border-radius: 4px;

  background-color: var(--coninsa-blue-300);

  color: var(--neutral-white);
}

.secondary:hover {

  background-color: var(--blue-600)
}

.i-secondary {

  border-width: 1px;

  border-style: solid;

  border-color: var(--neutral-transparent);

  background-color: var(--neutral-white);

  color: var(--coninsa-blue-300);
}

.i-secondary:hover {

  border-radius: 4px
}

.i-secondary:hover {

  border-color: var(--coninsa-blue-300)
}

.outline {

  border-width: 1px;

  border-style: solid;

  border-color: var(--gray-300);

  background-color: var(--neutral-transparent)
}

.i-outline {

  border-width: 1px;

  border-style: solid;

  border-color: var(--neutral-white);

  background-color: var(--neutral-transparent);

  color: var(--neutral-white)
}

.button--rounded {

  border-radius: 9999px
}

.button--small {

  font-size: var(--fs-sm);

  line-height: var(--lh-sm)
}

.button--medium {

  font-size: var(--fs-base);

  line-height: var(--lh-base)
}

.button--large {

  font-size: var(--fs-lg);

  line-height: var(--lh-lg)
}

.button--caps {

  padding-top: 0.5rem;

  padding-bottom: 0.5rem;

  font-size: var(--fs-sm);

  line-height: var(--lh-sm);

  font-weight: var(--fw-bold);

  text-transform: uppercase
}

.currency {

  min-width: -webkit-max-content;

  min-width: -moz-max-content;

  min-width: max-content;

  border-radius: 0.75rem;

  border-width: 1px;

  border-style: solid;

  border-color: var(--gray-400);

  background-color: var(--neutral-transparent);

  padding-left: 0.75rem;

  padding-right: 0.75rem;

  padding-top: 0.25rem;

  padding-bottom: 0.25rem;

  text-transform: uppercase;

  color: var(--gray-500);
}

.currency:hover {

  background-color: var(--gray-200)
}

.currency-active {

  min-width: -webkit-max-content;

  min-width: -moz-max-content;

  min-width: max-content;

  border-radius: 0.75rem;

  border-width: 1px;

  border-style: solid;

  border-color: var(--coninsa-green-500);

  background-color: var(--neutral-transparent);

  padding-left: 0.75rem;

  padding-right: 0.75rem;

  padding-top: 0.25rem;

  padding-bottom: 0.25rem;

  text-transform: uppercase;

  color: var(--coninsa-green-500);
}

.currency-active:hover {

  background-color: var(--coninsa-green-500)
}

.currency-active:hover {

  color: var(--coninsa-white-300)
}
`;