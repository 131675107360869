
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

@media (min-width: 768px) {

  :host {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 768px) {

  .container {
    display: grid;
  }
}

@media (min-width: 1280px) {

  .container {
    max-width: 72rem;
  }
}

@media (min-width: 768px){
  :host([layout='6/6']) .container{
    grid-template-columns:50% 50%;
  }

  :host([layout='3/9']) .container{
    grid-template-columns:25% 75%;
  }

  :host([layout='9/3']) .container{
    grid-template-columns:75% 25%;
  }
}

::slotted([slot='title']) {
  font-family: var(--ff-display);
  color: var(--coninsa-blue-900);
}

::slotted([slot='description']) {
  color: var(--gray-600);
}

::slotted(p) {
  margin-top: 0px;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

:host([heading-alignment='start']) .heading {
  justify-content: flex-start;
}

:host([heading-alignment='center']) .heading {
  justify-content: center;
}

:host([heading-alignment='end']) .heading {
  justify-content: flex-end;
}
`;