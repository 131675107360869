
import { css } from 'lit';
export default css`
/* Apply CSS Variables to the host element. */
:host {
--outline-breadcrumb--default-text-color-default:var(--outline-gray-100);

--outline-breadcrumb--default-link-color-default:var(--outline-white);

--outline-breadcrumb--default-link-color-hover:var(--outline-gray-300);

--outline-breadcrumb--default-link-color-focus:var(--outline-gray-300);

--outline-breadcrumb--default-link-decoration-default:none;

--outline-breadcrumb--default-link-decoration-hover:underline;

--outline-breadcrumb--default-link-decoration-focus:underline;

--outline-breadcrumb--default-bg-color:var(--outline-blue-600);

--outline-breadcrumb--default-bg-padding:var(--spacing-4) var(--spacing-4);

--outline-breadcrumb--default-separator-spacing:var(--spacing-2);
`;