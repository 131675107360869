
import { css } from 'lit';
export default css`
/* Apply CSS Variables to the host element. */
:host {
--outline-button--default--primary-padding:var(--outline-button--default-padding);
--outline-button--default--primary-radius:var(--outline-button--default-radius);
--outline-button--default--primary-bg-color-default:var(--outline-blue-600);
--outline-button--default--primary-text-color-default:var(--outline-white);
--outline-button--default--primary-weight-default:var(--outline-button--default-weight);
--outline-button--default--primary-ring-default:none;
--outline-button--default--primary-bg-color-hover:var(--outline-white);
--outline-button--default--primary-text-color-hover:var(--outline-blue-600);
--outline-button--default--primary-weight-hover:var(--outline-button--default-weight);
--outline-button--default--primary-ring-hover:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-blue-600);
--outline-button--default--primary-bg-color-focus:var(--outline-white);
--outline-button--default--primary-text-color-focus:var(--outline-blue-600);
--outline-button--default--primary-weight-focus:var(--outline-button--default-weight);
--outline-button--default--primary-ring-focus:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-blue-600);
--outline-button--default--primary--disabled-bg-color:var(--outline-white);
--outline-button--default--primary--disabled-text-color:var(--outline-gray-400);
--outline-button--default--primary--disabled-weight:var(--outline-button--default-weight);
--outline-button--default--primary--disabled-ring:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-gray-300);
--outline-button--default--primary--disabled-bg-color-focus:var(--outline-white);
--outline-button--default--primary--disabled-text-color-focus:var(--outline-gray-400);
--outline-button--default--primary--disabled-weight-focus:var(--outline-button--default-weight);
--outline-button--default--primary--disabled-ring-focus:var(--outline-ring-inset) 0 0 0 var(--outline-ring-offset-width) var(--outline-gray-400);
`;