import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-featured-tab.css.lit';

/**
 * The Coninsa Featured Tab component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-featured-tab')
export class CoFeaturedItem extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Activated.
   */
  @property({ attribute: 'activated', type: Boolean, reflect: true })
  activated = false;

  render(): TemplateResult {
    const classes = {
      'featured-tab': true,
      'featured-tab--activated': this.activated,
    };

    return html`
      <button type="button" class=${classMap(classes)}>
        <slot></slot>
      </button>
    `;
  }
}
