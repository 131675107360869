import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-button.css.lit';

export type ButtonVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'i-secondary'
  | 'outline'
  | 'i-outline'
  | 'currency'
  | 'currency-active';
export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonType = 'button' | 'submit';

/**
 * The Coninsa Search Message component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-button')
export class CoSearchMsg extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Button variant
   */
  @property({ type: String })
  variant: ButtonVariant = 'default';

  /**
   * Rounded value
   */
  @property({ type: Boolean })
  rounded: boolean;

  /**
   * Button type.
   */
  @property() type: ButtonType = 'button';

  /**
   * Button type.
   */
  @property() size: ButtonSize = 'medium';

  /**
   * Caps variant.
   */
  @property({ type: Boolean }) caps = false;

  _handleClick(e: Event) {
    // Check if the fire submit property is true or not.
    if (this.type == 'submit') {
      const form = this.closest('form') || this.closest('[as="form"]');

      if (form !== null) {
        e.preventDefault();

        // no form.submit() because we want to preserve constraint validation
        const fakeSubmit = document.createElement('button');

        fakeSubmit.type = 'submit';
        fakeSubmit.style.display = 'none';

        form.appendChild(fakeSubmit);
        fakeSubmit.click();
        fakeSubmit.remove();
      }
    }
  }

  render(): TemplateResult {
    const classes = classMap({
      'button': true,
      'button--rounded': this.rounded,
      'button--caps': this.caps,
      [this.variant]: true,
      [`button--${this.size}`]: true,
    });

    return html` <button class=${classes} @click=${this._handleClick}>
      <slot></slot>
    </button>`;
  }
}
