
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host{
  display:block;
}

p {
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
  margin-bottom:1em;
}

ul{
  list-style-type:disc;
  list-style-position:inside;
}

ol{
  list-style-type:decimal;
  list-style-position:inside;
}

li{
  padding-left:1em;
  margin-top:0.5em;
  margin-bottom:0.5em;
}

hr{
  border-color:var(--brand-primary);
  margin-top:2em;
  margin-bottom:2em;
}

blockquote{
  margin-top:1em;
  margin-bottom:1em;
  font-size:var(--fs-lg);
  font-weight:bold;
}

a {
  font-family: var(--ff-body);
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  color:var(--outline-link-color-default);
}

a:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color:var(--outline-link-color-hover) !important;
}

a:focus {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color:var(--outline-link-color-focus) !important;
}
`;