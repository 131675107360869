
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host{
  display:block;
}

  :host figure{
    margin:0;
    display:block;
    height:auto;
  }

figure{
  width:100%;
  height:100%;
  display:flex;
}

picture{
  aspect-ratio:default;
  overflow:hidden;
  display:flex;
}

img{
  object-fit:cover;
  height:auto;
  min-width:100%;
}

:host([image-ratio='1/1']) picture{
  aspect-ratio:1 / 1;
}

:host([image-ratio='3/2']) picture{
  aspect-ratio:3 / 2;
}

:host([image-ratio='3/4']) picture{
  aspect-ratio:3 / 4;
}

:host([image-ratio='4/3']) picture{
  aspect-ratio:4 / 3;
}

:host([image-ratio='5/4']) picture{
  aspect-ratio:5 / 4;
}

:host([image-ratio='16/9']) picture{
  aspect-ratio:16 / 9;
}

:host([image-ratio='9/16']) picture{
  aspect-ratio:9 / 16;
}

:host([image-ratio='21/9']) picture{
  aspect-ratio:21 / 9;
}
`;