import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-phone-link.css.lit';

/**
 * The Coninsa phone link component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-phone-link')
export class CoPhoneLink extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Label.
   */
  @property()
  label: string;

  /**
   * Phone.
   */
  @property()
  phone: string;

  /**
   * Link.
   */
  @property()
  link: string;

  render(): TemplateResult {
    return html`
      <div class="contact">
        ${this.label
          ? html`<div class="label">Desde <strong>${this.label}</strong></div>`
          : null}
        <a class="phone" href="tel:${this.link}" target="_blank">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
            ></path>
          </svg>
          ${this.phone}
        </a>
      </div>
    `;
  }
}
