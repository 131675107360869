
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

  display: block
}

.member {

  text-align: center;

  color: var(--coninsa-gray-900)
}

.image {

  margin-bottom: 0.75rem;

  height: 100%;

  width: 100%;
}

.image img {

  height: 15rem
}

.image img {

  width: 15rem
}

.image img {

  border-radius: 9999px
}

.image img {

  border-width: 1px
}

.image img {

  border-color: var(--outline-transparent)
}

.image img {

  background-color: var(--coninsa-white-300)
}

.image img {

  object-fit: contain
}

.name {

  font-family: var(--ff-display);

  font-size: var(--fs-xl);

  line-height: var(--lh-xl);

  font-weight: var(--fw-medium);

  opacity: 1
}

@media (min-width: 768px) {

  .name {

    font-size: var(--fs-2xl);

    line-height: var(--lh-2xl)
  }
}

.position {

  font-size: var(--fs-xs);

  line-height: var(--lh-xs)
}

@media (min-width: 768px) {

  .position {

    font-size: var(--fs-sm);

    line-height: var(--lh-sm)
  }
}
`;