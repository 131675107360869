import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, queryAssignedElements } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-auto-submit.css.lit';

export type ControlType = 'default' | 'inline';
export type ControlSize = 'small' | 'medium' | 'large';

/**
 * The Control component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-auto-submit')
export class CoAutoSubmit extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  @queryAssignedElements({ selector: 'select' })
  _selects!: Array<HTMLSelectElement>;

  redirectTo(key: string | null, value: string) {
    if (key) {
      const search = document.location.search;
      const params = new URLSearchParams(search);

      // Update sort operation param.
      params.set(key, value);

      // Redirect to new path.
      window.location.replace(
        `${document.location.pathname}?${params.toString()}`
      );
    }
  }

  /**
   * Get selected value from the current URL.
   * @param key String
   * @returns
   */
  getSelectedValueFromUrl(key: string): string | null {
    const search = document.location.search;
    const params = new URLSearchParams(search);

    return params.get(key);
  }

  firstUpdated(): void {
    if (this._selects.length) {
      const select = this._selects[0];
      const name = select.getAttribute('name');

      // Add change event listener.
      select.addEventListener('change', (event: Event) => {
        const value = (event.target as HTMLSelectElement).value;

        this.redirectTo(name, value);
      });

      // Auto select options based on the URL query strings.
      if (name) {
        const selectedValue = this.getSelectedValueFromUrl(name);

        if (selectedValue) {
          select.value = selectedValue;
        }
      }
    }
  }

  render(): TemplateResult {
    return html`
      <div class="auto-submit">
        <slot></slot>
      </div>
    `;
  }
}
