
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

  display: block;

  overflow: hidden;
}

  :host section {

  display: flex
}

  :host section {

  flex-direction: column
}

  :host section {

  overflow: hidden
}

  :host section {

  text-align: left
}

  :host p {

  margin-bottom: 2.5rem
}

.outline-shadow {

  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);

  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-rounded-2xl {

  border-radius: 1rem
}

.outline-bg-primary {

  background-color: var(--outline-blue-600)
}

.outline-bg-secondary {

  background-color: var(--outline-blue-300)
}

.outline-bg-tertiary {

  background-color: var(--outline-blue-100)
}

.outline-bg-black {

  background-color: var(--outline-black)
}

.outline-bg-white {

  background-color: var(--outline-white)
}

.outline-bg-gray {

  background-color: var(--outline-gray-300)
}

.outline-bg-outline-gray-100 {

  background-color: var(--outline-gray-100)
}
`;