
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  display: block
}

.header__heading {
  margin-bottom: 0.25rem;
  font-family: var(--ff-display);
  font-weight: var(--fw-normal);
  color: var(--brand-nonary)
}

.header__summary {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  color: var(--gray-700)
}

.header__divider {
  margin-bottom: 0.75rem;
  border-top:1px solid #ddd
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between
}
`;