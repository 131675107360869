
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host{

  display:inline-flex;

  vertical-align:middle;
}

.btn{
  display:flex;
  flex-wrap:nowrap;
  align-items:center;
  cursor:pointer;
  transition-property:color, background-color, border-color,
    fill, stroke, outline, outline-color, -webkit-text-decoration-color;
  transition-property:color, background-color, border-color,
    text-decoration-color, fill, stroke, outline, outline-color;
  transition-property:color, background-color, border-color,
    text-decoration-color, fill, stroke, outline, outline-color, -webkit-text-decoration-color;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:300ms;
  -webkit-text-decoration-line:none;
          text-decoration-line:none;
  outline:none;
  border:none;

  line-height:1.5;

  border-radius:var(--outline-button--default-radius)
  ;

  border-radius:var(
    --outline-btn-radius,
    var(--outline-button--default-radius)
  );
  font-family:var(--outline-button--default-family);
  font-family:var(--outline-btn-family, var(--outline-button--default-family));
  font-weight:var(--outline-button--default-weight);
  font-weight:var(--outline-btn-weight, var(--outline-button--default-weight));
  padding:var(--outline-button--default-padding);
  padding:var(--outline-btn-padding, var(--outline-button--default-padding));
}

.btn[aria-disabled='true']{
  pointer-events:none;
  cursor:not-allowed;
  -webkit-user-select:none;
     -moz-user-select:none;
          user-select:none;
}

.btn.primary{
  padding:var(--outline-button--default--primary-padding);
  padding:var(
    --outline--button--primary-padding,
    var(--outline-btn-padding, var(--outline-button--default--primary-padding))
  );
  border-radius:var(--outline-button--default-radius);
  border-radius:var(
    --outline--button--primary-radius,
    var(--outline-btn-radius, var(--outline-button--default-radius))
  );
  background-color:var(--outline-button--default--primary-bg-color-default)
  ;
  background-color:var(
    --outline--button--primary-bg-color-default,
    var(--outline-button--default--primary-bg-color-default)
  );
  color:var(--outline-button--default--primary-text-color-default)
  ;
  color:var(
    --outline--button--primary-text-color-default,
    var(--outline-button--default--primary-text-color-default)
  );
  font-weight:var(--outline-button--default--primary-weight-default)
  ;
  font-weight:var(
    --outline--button--primary-weight-default,
    var(--outline-button--default--primary-weight-default)
  );
  box-shadow:var(--outline-button--default--primary-ring-default)
  ;
  box-shadow:var(
    --outline--button--primary-ring-default,
    var(--outline-button--default--primary-ring-default)
  );
}

.btn.primary:hover{
    background-color:var(--outline-button--default--primary-bg-color-hover)
    ;
    background-color:var(
      --outline--button--primary-bg-color-hover,
      var(--outline-button--default--primary-bg-color-hover)
    );
    color:var(--outline-button--default--primary-text-color-hover)
    ;
    color:var(
      --outline--button--primary-text-color-hover,
      var(--outline-button--default--primary-text-color-hover)
    );
    font-weight:var(--outline-button--default--primary-weight-hover)
    ;
    font-weight:var(
      --outline--button--primary-weight-hover,
      var(--outline-button--default--primary-weight-hover)
    );
    box-shadow:var(--outline-button--default--primary-ring-hover)
    ;
    box-shadow:var(
      --outline--button--primary-ring-hover,
      var(--outline-button--default--primary-ring-hover)
    );
  }

.btn.primary:focus{
    background-color:var(--outline-button--default--primary-bg-color-focus)
    ;
    background-color:var(
      --outline--button--primary-bg-color-focus,
      var(--outline-button--default--primary-bg-color-focus)
    );
    color:var(--outline-button--default--primary-text-color-focus)
    ;
    color:var(
      --outline--button--primary-text-color-focus,
      var(--outline-button--default--primary-text-color-focus)
    );
    font-weight:var(--outline-button--default--primary-weight-focus)
    ;
    font-weight:var(
      --outline--button--primary-weight-focus,
      var(--outline-button--default--primary-weight-focus)
    );
    box-shadow:var(--outline-button--default--primary-ring-focus)
    ;
    box-shadow:var(
      --outline--button--primary-ring-focus,
      var(--outline-button--default--primary-ring-focus)
    );
  }

.btn.primary[aria-disabled='true']{
  background-color:var(--outline-button--default--primary--disabled-bg-color)
  ;
  background-color:var(
    --outline--button--primary--disabled-bg-color,
    var(--outline-button--default--primary--disabled-bg-color)
  );
  color:var(--outline-button--default--primary--disabled-text-color)
  ;
  color:var(
    --outline--button--primary--disabled-text-color,
    var(--outline-button--default--primary--disabled-text-color)
  );
  font-weight:var(--outline-button--default--primary--disabled-weight)
  ;
  font-weight:var(
    --outline--button--primary--disabled-weight,
    var(--outline-button--default--primary--disabled-weight)
  );
  box-shadow:var(--outline-button--default--primary--disabled-ring)
  ;
  box-shadow:var(
    --outline--button--primary--disabled-ring,
    var(--outline-button--default--primary--disabled-ring)
  );
}

.btn.primary[aria-disabled='true']:focus{
    background-color:var(--outline-button--default--primary--disabled-bg-color-focus)
    ;
    background-color:var(
      --outline--button--primary--disabled-bg-color-focus,
      var(--outline-button--default--primary--disabled-bg-color-focus)
    );
    color:var(--outline-button--default--primary--disabled-text-color-focus)
    ;
    color:var(
      --outline--button--primary--disabled-text-color-focus,
      var(--outline-button--default--primary--disabled-text-color-focus)
    );
    font-weight:var(--outline-button--default--primary--disabled-weight-focus)
    ;
    font-weight:var(
      --outline--button--primary--disabled-weight-focus,
      var(--outline-button--default--primary--disabled-weight-focus)
    );
    box-shadow:var(--outline-button--default--primary--disabled-ring-focus)
    ;
    box-shadow:var(
      --outline--button--primary--disabled-ring-focus,
      var(--outline-button--default--primary--disabled-ring-focus)
    );
  }

.btn.secondary{
  padding:var(--outline-button--default--secondary-padding)
    ;
  padding:var(
    --outline--button--secondary-padding,
    var(
      --outline-btn-padding,
      var(--outline-button--default--secondary-padding)
    )
  );
  border-radius:var(--outline-button--default-radius);
  border-radius:var(
    --outline--button--secondary-radius,
    var(--outline-btn-radius, var(--outline-button--default-radius))
  );

  background-color:var(--outline-button--default--secondary-bg-color-default)
  ;

  background-color:var(
    --outline--button--secondary-bg-color-default,
    var(--outline-button--default--secondary-bg-color-default)
  );
  color:var(--outline-button--default--secondary-text-color-default)
  ;
  color:var(
    --outline--button--secondary-text-color-default,
    var(--outline-button--default--secondary-text-color-default)
  );
  font-weight:var(--outline-button--default--secondary-weight-default)
  ;
  font-weight:var(
    --outline--button--secondary-weight-default,
    var(--outline-button--default--secondary-weight-default)
  );
  box-shadow:var(--outline-button--default--secondary-ring-default)
  ;
  box-shadow:var(
    --outline--button--secondary-ring-default,
    var(--outline-button--default--secondary-ring-default)
  );
}

.btn.secondary:hover{
    background-color:var(--outline-button--default--secondary-bg-color-hover)
    ;
    background-color:var(
      --outline--button--secondary-bg-color-hover,
      var(--outline-button--default--secondary-bg-color-hover)
    );
    color:var(--outline-button--default--secondary-text-color-hover)
    ;
    color:var(
      --outline--button--secondary-text-color-hover,
      var(--outline-button--default--secondary-text-color-hover)
    );
    font-weight:var(--outline-button--default--secondary-weight-hover)
    ;
    font-weight:var(
      --outline--button--secondary-weight-hover,
      var(--outline-button--default--secondary-weight-hover)
    );
    box-shadow:var(--outline-button--default--secondary-ring-hover)
    ;
    box-shadow:var(
      --outline--button--secondary-ring-hover,
      var(--outline-button--default--secondary-ring-hover)
    );
  }

.btn.secondary:focus{
    background-color:var(--outline-button--default--secondary-bg-color-focus)
    ;
    background-color:var(
      --outline--button--secondary-bg-color-focus,
      var(--outline-button--default--secondary-bg-color-focus)
    );
    color:var(--outline-button--default--secondary-text-color-focus)
    ;
    color:var(
      --outline--button--secondary-text-color-focus,
      var(--outline-button--default--secondary-text-color-focus)
    );
    font-weight:var(--outline-button--default--secondary-weight-focus)
    ;
    font-weight:var(
      --outline--button--secondary-weight-focus,
      var(--outline-button--default--secondary-weight-focus)
    );
    box-shadow:var(--outline-button--default--secondary-ring-focus)
    ;
    box-shadow:var(
      --outline--button--secondary-ring-focus,
      var(--outline-button--default--secondary-ring-focus)
    );
  }

.btn.secondary[aria-disabled='true']{
  background-color:var(--outline-button--default--secondary--disabled-bg-color)
  ;
  background-color:var(
    --outline--button--secondary--disabled-bg-color,
    var(--outline-button--default--secondary--disabled-bg-color)
  );
  color:var(--outline-button--default--secondary--disabled-text-color)
  ;
  color:var(
    --outline--button--secondary--disabled-text-color,
    var(--outline-button--default--secondary--disabled-text-color)
  );
  font-weight:var(--outline-button--default--secondary--disabled-weight)
  ;
  font-weight:var(
    --outline--button--secondary--disabled-weight,
    var(--outline-button--default--secondary--disabled-weight)
  );
  box-shadow:var(--outline-button--default--secondary--disabled-ring)
  ;
  box-shadow:var(
    --outline--button--secondary--disabled-ring,
    var(--outline-button--default--secondary--disabled-ring)
  );
}

.btn.secondary[aria-disabled='true']:focus{
    background-color:var(--outline-button--default--secondary--disabled-bg-color-focus)
    ;
    background-color:var(
      --outline--button--secondary--disabled-bg-color-focus,
      var(--outline-button--default--secondary--disabled-bg-color-focus)
    );
    color:var(--outline-button--default--secondary--disabled-text-color-focus)
    ;
    color:var(
      --outline--button--secondary--disabled-text-color-focus,
      var(--outline-button--default--secondary--disabled-text-color-focus)
    );
    font-weight:var(--outline-button--default--secondary--disabled-weight-focus)
    ;
    font-weight:var(
      --outline--button--secondary--disabled-weight-focus,
      var(--outline-button--default--secondary--disabled-weight-focus)
    );
    box-shadow:var(--outline-button--default--secondary--disabled-ring-focus)
    ;
    box-shadow:var(
      --outline--button--secondary--disabled-ring-focus,
      var(--outline-button--default--secondary--disabled-ring-focus)
    );
  }

.btn.tertiary{
  padding:var(--outline-button--default--tertiary-padding);
  padding:var(
    --outline--button--tertiary-padding,
    var(--outline-btn-padding, var(--outline-button--default--tertiary-padding))
  );
  border-radius:var(--outline-button--default-radius);
  border-radius:var(
    --outline--button--tertiary-radius,
    var(--outline-btn-radius, var(--outline-button--default-radius))
  );

  background-color:var(--outline-button--default--tertiary-bg-color-default)
  ;

  background-color:var(
    --outline--button--tertiary-bg-color-default,
    var(--outline-button--default--tertiary-bg-color-default)
  );
  color:var(--outline-button--default--tertiary-text-color-default)
  ;
  color:var(
    --outline--button--tertiary-text-color-default,
    var(--outline-button--default--tertiary-text-color-default)
  );
  font-weight:var(--outline-button--default--tertiary-weight-default)
  ;
  font-weight:var(
    --outline--button--tertiary-weight-default,
    var(--outline-button--default--tertiary-weight-default)
  );
  box-shadow:var(--outline-button--default--tertiary-ring-default)
  ;
  box-shadow:var(
    --outline--button--tertiary-ring-default,
    var(--outline-button--default--tertiary-ring-default)
  );
}

.btn.tertiary:hover{
    background-color:var(--outline-button--default--tertiary-bg-color-hover)
    ;
    background-color:var(
      --outline--button--tertiary-bg-color-hover,
      var(--outline-button--default--tertiary-bg-color-hover)
    );
    color:var(--outline-button--default--tertiary-text-color-hover)
    ;
    color:var(
      --outline--button--tertiary-text-color-hover,
      var(--outline-button--default--tertiary-text-color-hover)
    );
    font-weight:var(--outline-button--default--tertiary-weight-hover)
    ;
    font-weight:var(
      --outline--button--tertiary-weight-hover,
      var(--outline-button--default--tertiary-weight-hover)
    );
    box-shadow:var(--outline-button--default--tertiary-ring-hover)
    ;
    box-shadow:var(
      --outline--button--tertiary-ring-hover,
      var(--outline-button--default--tertiary-ring-hover)
    );
  }

.btn.tertiary:focus{
    background-color:var(--outline-button--default--tertiary-bg-color-focus)
    ;
    background-color:var(
      --outline--button--tertiary-bg-color-focus,
      var(--outline-button--default--tertiary-bg-color-focus)
    );
    color:var(--outline-button--default--tertiary-text-color-focus)
    ;
    color:var(
      --outline--button--tertiary-text-color-focus,
      var(--outline-button--default--tertiary-text-color-focus)
    );
    font-weight:var(--outline-button--default--tertiary-weight-focus)
    ;
    font-weight:var(
      --outline--button--tertiary-weight-focus,
      var(--outline-button--default--tertiary-weight-focus)
    );
    box-shadow:var(--outline-button--default--tertiary-ring-focus)
    ;
    box-shadow:var(
      --outline--button--tertiary-ring-focus,
      var(--outline-button--default--tertiary-ring-focus)
    );
  }

.btn.tertiary[aria-disabled='true']{
  background-color:var(--outline-button--default--tertiary--disabled-bg-color)
  ;
  background-color:var(
    --outline--button--tertiary--disabled-bg-color,
    var(--outline-button--default--tertiary--disabled-bg-color)
  );
  color:var(--outline-button--default--tertiary--disabled-text-color)
  ;
  color:var(
    --outline--button--tertiary--disabled-text-color,
    var(--outline-button--default--tertiary--disabled-text-color)
  );
  font-weight:var(--outline-button--default--tertiary--disabled-weight)
  ;
  font-weight:var(
    --outline--button--tertiary--disabled-weight,
    var(--outline-button--default--tertiary--disabled-weight)
  );
  box-shadow:var(--outline-button--default--tertiary--disabled-ring)
  ;
  box-shadow:var(
    --outline--button--tertiary--disabled-ring,
    var(--outline-button--default--tertiary--disabled-ring)
  );
}

.btn.tertiary[aria-disabled='true']:focus{
    background-color:var(--outline-button--default--tertiary--disabled-bg-color-focus)
    ;
    background-color:var(
      --outline--button--tertiary--disabled-bg-color-focus,
      var(--outline-button--default--tertiary--disabled-bg-color-focus)
    );
    color:var(--outline-button--default--tertiary--disabled-text-color-focus)
    ;
    color:var(
      --outline--button--tertiary--disabled-text-color-focus,
      var(--outline-button--default--tertiary--disabled-text-color-focus)
    );
    font-weight:var(--outline-button--default--tertiary--disabled-weight-focus)
    ;
    font-weight:var(
      --outline--button--tertiary--disabled-weight-focus,
      var(--outline-button--default--tertiary--disabled-weight-focus)
    );
    box-shadow:var(--outline-button--default--tertiary--disabled-ring-focus)
    ;
    box-shadow:var(
      --outline--button--tertiary--disabled-ring-focus,
      var(--outline-button--default--tertiary--disabled-ring-focus)
    );
  }

.btn.link{
  padding:var(--outline-button--default--link-padding);
  padding:var(
    --outline--button--link-padding,
    var(--outline-btn-padding, var(--outline-button--default--link-padding))
  );
  border-radius:var(--outline-button--default-radius);
  border-radius:var(
    --outline--button--link-radius,
    var(--outline-btn-radius, var(--outline-button--default-radius))
  );

  background-color:var(--outline-button--default--link-bg-color-default)
  ;

  background-color:var(
    --outline--button--link-bg-color-default,
    var(--outline-button--default--link-bg-color-default)
  );
  color:var(--outline-button--default--link-text-color-default)
  ;
  color:var(
    --outline--button--link-text-color-default,
    var(--outline-button--default--link-text-color-default)
  );
  font-weight:var(--outline-button--default--link-weight-default)
  ;
  font-weight:var(
    --outline--button--link-weight-default,
    var(--outline-button--default--link-weight-default)
  );
  -webkit-text-decoration:var(--outline-button--default--link-decoration-default)
  ;
          text-decoration:var(--outline-button--default--link-decoration-default)
  ;
  -webkit-text-decoration:var(
    --outline--button--link-decoration-default,
    var(--outline-button--default--link-decoration-default)
  );
          text-decoration:var(
    --outline--button--link-decoration-default,
    var(--outline-button--default--link-decoration-default)
  );
  box-shadow:var(--outline-button--default--link-ring-default)
  ;
  box-shadow:var(
    --outline--button--link-ring-default,
    var(--outline-button--default--link-ring-default)
  );
}

.btn.link:hover{
    background-color:var(--outline-button--default--link-bg-color-hover)
    ;
    background-color:var(
      --outline--button--link-bg-color-hover,
      var(--outline-button--default--link-bg-color-hover)
    );
    color:var(--outline-button--default--link-text-color-hover)
    ;
    color:var(
      --outline--button--link-text-color-hover,
      var(--outline-button--default--link-text-color-hover)
    );
    font-weight:var(--outline-button--default--link-weight-hover)
    ;
    font-weight:var(
      --outline--button--link-weight-hover,
      var(--outline-button--default--link-weight-hover)
    );
    -webkit-text-decoration:var(--outline-button--default--link-decoration-hover)
    ;
            text-decoration:var(--outline-button--default--link-decoration-hover)
    ;
    -webkit-text-decoration:var(
      --outline--button--link-decoration-hover,
      var(--outline-button--default--link-decoration-hover)
    );
            text-decoration:var(
      --outline--button--link-decoration-hover,
      var(--outline-button--default--link-decoration-hover)
    );
    box-shadow:var(--outline-button--default--link-ring-hover)
    ;
    box-shadow:var(
      --outline--button--link-ring-hover,
      var(--outline-button--default--link-ring-hover)
    );
  }

.btn.link:focus{
    background-color:var(--outline-button--default--link-bg-color-focus)
    ;
    background-color:var(
      --outline--button--link-bg-color-focus,
      var(--outline-button--default--link-bg-color-focus)
    );
    color:var(--outline-button--default--link-text-color-focus)
    ;
    color:var(
      --outline--button--link-text-color-focus,
      var(--outline-button--default--link-text-color-focus)
    );
    font-weight:var(--outline-button--default--link-weight-focus)
    ;
    font-weight:var(
      --outline--button--link-weight-focus,
      var(--outline-button--default--link-weight-focus)
    );
    -webkit-text-decoration:var(--outline-button--default--link-decoration-focus)
    ;
            text-decoration:var(--outline-button--default--link-decoration-focus)
    ;
    -webkit-text-decoration:var(
      --outline--button--link-decoration-focus,
      var(--outline-button--default--link-decoration-focus)
    );
            text-decoration:var(
      --outline--button--link-decoration-focus,
      var(--outline-button--default--link-decoration-focus)
    );
    box-shadow:var(--outline-button--default--link-ring-focus)
    ;
    box-shadow:var(
      --outline--button--link-ring-focus,
      var(--outline-button--default--link-ring-focus)
    );
  }

.btn.link[aria-disabled='true']{
  background-color:var(--outline-button--default--link--disabled-bg-color)
  ;
  background-color:var(
    --outline--button--link--disabled-bg-color,
    var(--outline-button--default--link--disabled-bg-color)
  );
  color:var(--outline-button--default--link--disabled-text-color)
  ;
  color:var(
    --outline--button--link--disabled-text-color,
    var(--outline-button--default--link--disabled-text-color)
  );
  font-weight:var(--outline-button--default--link--disabled-weight)
  ;
  font-weight:var(
    --outline--button--link--disabled-weight,
    var(--outline-button--default--link--disabled-weight)
  );
  -webkit-text-decoration:var(--outline-button--default--link--disabled-decoration)
  ;
          text-decoration:var(--outline-button--default--link--disabled-decoration)
  ;
  -webkit-text-decoration:var(
    --outline--button--link--disabled-decoration,
    var(--outline-button--default--link--disabled-decoration)
  );
          text-decoration:var(
    --outline--button--link--disabled-decoration,
    var(--outline-button--default--link--disabled-decoration)
  );
  box-shadow:var(--outline-button--default--link--disabled-ring)
  ;
  box-shadow:var(
    --outline--button--link--disabled-ring,
    var(--outline-button--default--link--disabled-ring)
  );
}

.btn.link[aria-disabled='true']:focus{
    background-color:var(--outline-button--default--link--disabled-bg-color-focus)
    ;
    background-color:var(
      --outline--button--link--disabled-bg-color-focus,
      var(--outline-button--default--link--disabled-bg-color-focus)
    );
    color:var(--outline-button--default--link--disabled-text-color-focus)
    ;
    color:var(
      --outline--button--link--disabled-text-color-focus,
      var(--outline-button--default--link--disabled-text-color-focus)
    );
    font-weight:var(--outline-button--default--link--disabled-weight-focus)
    ;
    font-weight:var(
      --outline--button--link--disabled-weight-focus,
      var(--outline-button--default--link--disabled-weight-focus)
    );
    -webkit-text-decoration:var(--outline-button--default--link--disabled-decoration-focus)
    ;
            text-decoration:var(--outline-button--default--link--disabled-decoration-focus)
    ;
    -webkit-text-decoration:var(
      --outline--button--link--disabled-decoration-focus,
      var(--outline-button--default--link--disabled-decoration-focus)
    );
            text-decoration:var(
      --outline--button--link--disabled-decoration-focus,
      var(--outline-button--default--link--disabled-decoration-focus)
    );
    box-shadow:var(--outline-button--default--link--disabled-ring-focus)
    ;
    box-shadow:var(
      --outline--button--link--disabled-ring-focus,
      var(--outline-button--default--link--disabled-ring-focus)
    );
  }

:host(.icon__button) .btn.primary{
    border-top-left-radius:0px !important;
    border-bottom-left-radius:0px !important;
  }

:host(.icon__button) .btn.secondary{
    border-top-left-radius:0px !important;
    border-bottom-left-radius:0px !important;
  }

:host(.icon__button) .btn.tertiary{
    border-top-left-radius:0px !important;
    border-bottom-left-radius:0px !important;
  }

:host(.icon__button) .btn.link{
    border:0 !important;
    margin-right:0 !important;
  }

.btn.small{
  font-size:var(--fs-sm);
  line-height:var(--lh-sm);
}

.btn.medium{
  font-size:var(--fs-base);
  line-height:var(--lh-base);
}

.btn.large{
  font-size:var(--fs-lg);
  line-height:var(--lh-lg);
}
`;