import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-section.css.lit';
import '../../base/outline-container/outline-container';

export type SectionAlignment = 'vertical' | 'horizontal';
export type SectionHeadingAlignment = 'start' | 'center' | 'end';

export type SectionLayout = '6/6' | '3/9' | '9/3';

/**
 * The Coninsa Section component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-section')
export class CoSection extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Alignment.
   */
  @property()
  alignment: SectionAlignment = 'horizontal';

  /**
   * Layout.
   */
  @property({ reflect: true })
  layout: SectionLayout = '3/9';

  /**
   * Heading alignment.
   */
  @property({ attribute: 'heading-alignment' })
  headingAlignment: SectionHeadingAlignment = 'start';

  render(): TemplateResult {
    return html`
      <div class="section">
        <div class="container">
          <div class="heading">
            <slot name="title"></slot>
            <slot name="description"></slot>
          </div>

          <slot name="content"></slot>
        </div>
      </div>
    `;
  }
}
