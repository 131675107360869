
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

  display: block
}

.contact {

  text-align: center
}

.label {

  margin-bottom: 0.25rem;

  font-size: var(--fs-base);

  line-height: var(--lh-base);

  color: var(--coninsa-white-300)
}

@media (min-width: 768px) {

  .label {

    font-size: var(--fs-xl);

    line-height: var(--lh-xl)
  }
}

.phone {

  display: inline-flex;

  border-radius: 1.5rem;

  border-width: 2px;

  border-style: solid;

  border-color: var(--coninsa-white-300);

  background-color: var(--coninsa-white-300);

  padding-left: 1.5rem;

  padding-right: 1.5rem;

  padding-top: 0.5rem;

  padding-bottom: 0.5rem;

  font-size: var(--fs-xl);

  line-height: var(--lh-xl);

  font-weight: var(--fw-semibold);

  color: var(--coninsa-blue-300);

  -webkit-text-decoration-line: none;

          text-decoration-line: none
}

@media (min-width: 768px) {

  .phone {

    font-size: var(--fs-2xl);

    line-height: var(--lh-2xl)
  }
}

.phone svg {

  margin-right: 1rem
}

.phone:hover {

  background-color: var(--coninsa-green-300);

  color: var(--coninsa-white-300)
}
`;