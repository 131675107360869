import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-facet.css.lit';

/**
 * The Coninsa Facet component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-facet')
export class CoFacet extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Facet name
   */
  @property({ type: String })
  name: string;

  handleSlotchange(e: { target: HTMLSlotElement }) {
    const childNodes = e.target.assignedNodes({ flatten: true });
    const slotText = Array.prototype.map
      .call(childNodes, node => {
        return node.textContent ? node.textContent : '';
      })
      .join('');

    const isActive = slotText.search(/\(-\)/) !== -1;

    if (isActive) {
      this.open();
    }
  }

  open() {
    const details = this.renderRoot.querySelector('details');
    details?.setAttribute('open', '');
  }

  render(): TemplateResult {
    return html`
      <details>
        <summary>${this.name}</summary>
        <slot @slotchange=${this.handleSlotchange}></slot>
      </details>
    `;
  }
}
