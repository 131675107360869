
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.tag {

    margin-right: 0.5rem;

    display: inline-flex;

    align-items: center;

    gap: 0.25rem;

    background-color: var(--gray-200);

    padding: 0.25rem;

    font-size: var(--fs-xs);

    line-height: var(--lh-xs);

    color: var(--gray-600);

    -webkit-text-decoration-line: none;

            text-decoration-line: none
}

.close-icon {

    height: 0.75rem;

    width: 0.75rem
}
`;