import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../../base/outline-element/outline-element';
import componentStyles from './co-item-diagram.css.lit';

/**
 * The Coninsa Image Cap component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-item-diagram')
export class CoItemDiagram extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Image Url.
   */
  @property({ attribute: 'image-url' })
  ImageUrl: string;

  /**
   * Heading.
   */
  @property()
  heading: string;

  render(): TemplateResult {
    return html`
      <div class="item-diagram">
        <img class="image" src=${this.ImageUrl} />
        <div class="content">
          <outline-heading
            slot="title"
            level="h5"
            levelStyle="bold"
            class="heading"
          >
            ${this.heading}
          </outline-heading>
          <slot></slot>
        </div>
      </div>
    `;
  }
}
