
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  display: block;
}

.outstanding {
  border-radius: 0.125rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  color: var(--neutral-white);
}

.primary {
  background-color: var(--coninsa-green-300);
}

.primary::after{
    border-left:20px solid #80c342;
  }

.secondary {
  background-color: var(--coninsa-red-300);
}

.secondary::after{
    border-left:20px solid #d52829;
  }

.tertiary {
  background-color: var(--coninsa-blue-900);
}

.tertiary::after{
    border-left:20px solid #25317a;
  }

.quaternary {
  background-color: var(--coninsa-green-900);
}

.quaternary::after{
    border-left:20px solid #019a3c;
  }

.quinary {
  background-color: var(--coninsa-yellow-300);
  color: var(--coninsa-blue-900);
}

.quinary::after{
    border-left:20px solid #fdc300;
  }

.senary {
  background-color: var(--coninsa-blue-300);
}

.senary::after{
    border-left:20px solid #005fe2;
  }
`;