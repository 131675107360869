
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {
  display: block;
}

@media (min-width: 768px) {

  .three-item-diagram {
    text-align: center;
  }
}

.heading {
  display: inline-flex;
  border-radius: 1.5rem;
  background-color: var(--coninsa-yellow-300);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  color: var(--coninsa-blue-900);
}

@media (min-width: 768px) {

  .heading {
    margin-bottom: 5rem;
  }

  .heading {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .heading {
    font-size: var(--fs-lg);
    line-height: var(--lh-lg);
  }
}

.heading{
  box-shadow:0px 3px 6px #00000029;
}

.content {
  gap: 1rem;
}

@media (min-width: 768px) {

  .content {
    display: grid;
  }

  .content {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
`;