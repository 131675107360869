
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

    display: block
}

.tab-item {

    margin-bottom: 0.5rem;

    display: flex;

    justify-content: space-between
}

@media (min-width: 768px) {

    .tab-item {

        display: block
    }
}

.label {

    font-size: var(--fs-sm);

    line-height: var(--lh-sm);

    color: var(--outline-gray-500);

    opacity: 1
}

@media (min-width: 768px) {

    .label {

        margin-bottom: 0.25rem
    }

    .label {

        font-size: var(--fs-xs);

        line-height: var(--lh-xs)
    }
}

.value {

    font-family: var(--ff-display);

    font-weight: var(--fw-medium);

    color: var(--gray-900)
}
`;