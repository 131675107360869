import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-member-team.css.lit';

/**
 * The Coninsa member team component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-member-team')
export class CoMemberTeam extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Name.
   */
  @property()
  name: string;

  /**
   * Position.
   */
  @property()
  position: string;

  /**
   * Photo.
   */
  @property({ attribute: 'photo-url' })
  photoUrl: string;

  /**
   * Url.
   */
  @property()
  url?: string;

  render(): TemplateResult {
    return html`
      <div class="member">
        <div class="image">
          <img src=${this.photoUrl} />
        </div>

        <div class="info">
          ${this.name ? html`<div class="name">${this.name}</div>` : null}
          <div class="position">${this.position}</div>
        </div>
      </div>
    `;
  }
}
