import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit-html/directives/class-map.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-dropdown.css.lit';
import '../../base/outline-icon/outline-icon';

export type DropdownVariant = 'default' | 'alternative';

/**
 * The dropdown component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-dropdown')
export class CoDropdown extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('click', this._handleClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.addEventListener('click', this._handleClick);
  }

  /**
   * Detect clicks outside of a Web Component.
   * @param event
   */
  _handleClick = (event: Event) => {
    if (!event.composedPath().includes(this)) {
      this.isOpen = false;
    }
  };

  @state() isOpen = false;

  /**
   * Dropdown name.
   */
  @property({ type: String })
  name: string;

  /**
   * Dropdown variant.
   */
  @property() variant: DropdownVariant = 'default';

  handleClick = () => {
    this.isOpen = !this.isOpen;
  };

  renderIcon(): TemplateResult {
    return this.isOpen
      ? html`<outline-icon
          name="chevron-up"
          library="system"
          size="15px"
        ></outline-icon>`
      : html`<outline-icon
          name="chevron-down"
          library="system"
          size="15px"
        ></outline-icon>`;
  }

  render(): TemplateResult {
    const classes = {
      'dropdown': true,
      'dropdown--active': this.isOpen,
      [`dropdown--${this.variant}`]: true,
    };

    return html`
      <div class=${classMap(classes)}>
        <button class="trigger" @click=${this.handleClick}>
          ${this.name} ${this.renderIcon()}
        </button>

        <div class="content">
          <slot></slot>
        </div>
      </div>
    `;
  }
}
