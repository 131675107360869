import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-banner.css.lit';
import '../../base/outline-container/outline-container';
import { classMap } from 'lit/directives/class-map.js';

/**
 * The Coninsa Banner component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-banner')
export class CoBanner extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  @property({ attribute: 'image-url' }) imageUrl: string;
  @property({ attribute: 'image-mobile-url' }) imageMobileUrl: string;

  @property({ type: Boolean, reflect: true })
  symmetric = false;

  render(): TemplateResult {
    const baseClasses = classMap({
      'banner': true,
      'banner--symmetric': this.symmetric,
    });

    return html`
      <style>
        @media (min-width: 768px) {
          :host {
            background: url(${this.imageUrl});
          }
        }
      </style>
      <div class="${baseClasses}">
        <div class="container">
          <div class="content">
            <slot></slot>
          </div>
        </div>
        <img src=${this.imageMobileUrl} class="image" />
      </div>
    `;
  }
}
