
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
.accordion-title {
  font-family: var(--ff-display);
  font-size: var(--fs-4xl);
  line-height: var(--lh-4xl);
  color: var(--outline-soft-black);
}

.accordion-title.mobile {
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
  color: var(--outline-soft-black);
}

::slotted(outline-heading){
  margin-bottom:2.5rem;
}
`;