
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host {

    display: block
}

.button {

    display: flex
}

.button__title {

    margin-left: 0px;

    margin-right: 0px;

    margin-top: auto;

    margin-bottom: auto;

    font-size: var(--fs-sm);

    line-height: var(--lh-sm);

    color: var(--neutral-white);

    opacity: 1
}

.button__label {

    margin-left: 1.5rem;

    border-radius: 1rem;

    border-width: 1px;

    border-style: solid;

    border-color: var(--neutral-white);

    background-color: var(--outline-transparent);

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    font-size: var(--fs-sm);

    line-height: var(--lh-sm);

    color: var(--neutral-white)
}
`;