
import { css } from 'lit';
export default css`
/* Apply CSS Variables to the host element. */
:host {
--outline-admin-links--default-color-default:var(--outline-gray-600);

--outline-admin-links--default-color-hover:var(--outline-soft-black);

--outline-admin-links--default-color-focus:var(--outline-soft-black);

--outline-admin-links--default-color-active:var(--outline-white);

--outline-admin-links--default-bg-default:var(--outline-gray-100);

--outline-admin-links--default-bg-hover:var(--outline-gray-300);

--outline-admin-links--default-bg-focus:var(--outline-gray-300);

--outline-admin-links--default-bg-active:var(--outline-blue-600);

--outline-admin-links--default-y-spacing:var(--spacing-4);

--outline-admin-links--default-link-y-spacing:var(--spacing-4);

--outline-admin-links--default-link-x-spacing:var(--spacing-6);
`;