import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-control.css.lit';

export type ControlType = 'default' | 'inline';
export type ControlSize = 'small' | 'medium' | 'large';

/**
 * The Control component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-control')
export class CoControl extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Label text.
   */
  @property({ type: String })
  label: string;

  /**
   * Variant type.
   */
  @property() variant: ControlType = 'default';

  /**
   * Control size.
   */
  @property() size: ControlSize = 'medium';

  render(): TemplateResult {
    const classes = {
      control: true,
      [`control--${this.variant}`]: true,
      [`control--${this.size}`]: true,
    };

    return html`
      <div class=${classMap(classes)}>
        <label>${this.label}</label>
        <slot></slot>
      </div>
    `;
  }
}
