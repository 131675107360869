import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, queryAssignedElements } from 'lit/decorators.js';

// Our base component, which all others extend.
import { OutlineElement } from '../../base/outline-element/outline-element';
import componentStyles from './co-auto-submit-radios.css.lit';

/**
 * The Control component
 * @slot - The default, and only slot for this element.
 */
@customElement('co-auto-submit-radios')
export class CoAutoSubmit extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  @queryAssignedElements({ selector: 'input' })
  _inputs!: Array<HTMLInputElement>;

  redirectTo(key: string | null, value: string) {
    if (key) {
      const search = document.location.search;
      const params = new URLSearchParams(search);

      // Update sort operation param.
      params.set(key, value);

      // Redirect to new path.
      window.location.replace(
        `${document.location.pathname}?${params.toString()}`
      );
    }
  }

  /**
   * Get selected value from the current URL.
   * @param key String
   * @returns
   */
  getSelectedValueFromUrl(key: string): string | null {
    const search = document.location.search;
    const params = new URLSearchParams(search);

    return params.get(key);
  }

  firstUpdated(): void {
    if (this._inputs.length) {
      const input = this._inputs[0];
      const name = input.getAttribute('name');

      // Auto select options based on the URL query strings.
      if (name) {
        const selectedValue = this.getSelectedValueFromUrl(name);

        if (selectedValue) {
          const selectedInput = this._inputs.find(
            i => i.value == selectedValue
          );

          if (selectedInput) {
            selectedInput.checked = true;
          }
        }
      }
    }
  }

  _handleClick(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    const name = (event.target as HTMLInputElement).getAttribute('name');

    if (value && name) {
      this.redirectTo(name, value);
    }
  }

  render(): TemplateResult {
    return html`
      <div class="auto-submit" @click=${this._handleClick}>
        <slot></slot>
      </div>
    `;
  }
}
