
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
/* Apply proper CSS for accessibly hiding elements to each component. */
:host([aria-hidden="true"]),
[aria-hidden="true"],
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
/* Apply component specific CSS */
:host{

    display: block;

    background-size: cover;

    background-position: bottom;

    background-repeat: no-repeat;

    padding-top: 3.5rem
}

@media (min-width: 768px){

    :host{

        background-position: center
    }

    :host{

        padding-top: 3.5rem;

        padding-bottom: 3.5rem
    }
}

.banner{}

.container{

    margin-left: auto;

    margin-right: auto;

    box-sizing: border-box;

    gap: 2rem;

    padding-left: 1rem;

    padding-right: 1rem
}

@media (min-width: 1280px){

    .container{

        max-width: 72rem
    }
}

.image{

    margin-top: 1.5rem;

    display: block;

    width: 100%
}

@media (min-width: 768px){

    .image{

        display: none
    }

    .banner .content{

        width: 50%
    }
}

@media (min-width: 1024px){

    .banner .content{

        width: 33.333333%
    }
}

@media (min-width: 768px){

    .banner--symmetric .content{

        width: 50%
    }
}

@media (min-width: 1024px){

    .banner--symmetric .content{

        width: 50%
    }
}`;